import React, { Component } from 'react';
import { Cart } from '../components/Cart';
import Service from '../components/Service';
import duix from 'duix';

// export const numberOfCartProducts = () => {
//     return Cart.getCartProductNumber();
// }

class CartDisplayMobile extends Component {

    constructor(props){
        super(props);
        this.state = {
            numberOfProducts: 0,
            total: 0
        }
    }

    unsubscribe = []
 
    componentDidMount(){
        Cart.initCart(); // get cart items from the local storage
        this.unsubscribe[0] = duix.subscribe('cartItems', this.numberOfCartProducts);
    }

    componentWillUnmount(){
        this.unsubscribe[0]();
    }

    numberOfCartProducts=(cart)=>{
        this.setState({
            numberOfProducts: cart.cartProducts,
            total: cart.total
        });
    }

    render(){


        return(

            <span>
                {/* <span className="cart-text">Korpa<br />{this.state.total} RSD.</span> */}
                <i className="fa fa-shopping-cart cart-image" ></i>
                <span className="mobile-cart-number"> {this.state.numberOfProducts} </span>
            </span>

        );
    }

}

export default CartDisplayMobile;
