
import React, { Component, Fragment } from 'react';
import { Cart } from '../components/Cart';
import Categories from '../components/Categories';
import Service from '../components/Service';
import { Auth } from '../components/Auth';
import duix from 'duix';
import RelatedProducts from '../components/RelatedProducts';
import AddThis from '../components/AddThis';
import renderHTML from 'react-render-html';
import {NotificationManager} from 'react-notifications';
//import Popup from "reactjs-popup";
import Slider from "react-slick";
import Sticker from '../components/Sticker';
import { Helmet } from 'react-helmet';

import StarRatings from 'react-star-ratings';

const Info = props => (
  
    <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
);

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

function getItemDiscount (item){
    //(40-30)/40 * 100;
    if(!item.discount){
        return Math.round((parseInt(item.full_price) - parseInt(item.price)) /  parseInt(item.full_price) * 100);
    }
    return parseInt(item.discount);
}

function product_1() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: this.state.selectedImage,
            nextArrow: <div>{'>'}</div>,
            prevArrow: <div>{'<'}</div>
        };

        return(
            
            <div className="product-single" >
                

                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                {this.state.products.length < 1 &&
                    <div className="right-content"><h3>Nije pronadjen željeni proizvod. Moguće je da je proizvod u medjuvremenu postao nedostupan.</h3></div>
                }
                {this.state.products.length > 0 &&
                <div className="right-content">
                {/* {this.state.products.length > 0 ( */}
                {/* <h3>Knjige</h3> */}
                {this.state.products.map(function(item, index){
                    let stickerNew = false;
                    return(
                        <div key={index}>
                            <Helmet>
                                <title>{Service.websiteTitleName} | {item.title}</title>
                            </Helmet>
                            
                            <div className="product-left">

                                  {item.categories.map(function(categories, index){
                                    return(
                                        <span key={index}>
                                            {categories.name === "Novi naslovi" &&
                                                <Fragment>{stickerNew = true}</Fragment>
                                            }       
                                        </span>
                                    )}, this)
                                }

                                
                                <div className='product-main-image'>
                                    <img alt="" onClick={this.changeImage} id={0} src={this.state.currentImage}  width={this.state.imageWidth} /> 
                                </div>
                                {item.preorder == 1 &&

                                    <div className="preorder product-preorder">U pretprodaji</div>
                                }
                            </div>
                            <div className="product-mid">

                                    <div className="stickers-container product-stickers">
                                        {item.status !== 2 &&
                                            <Fragment>
                                                {item.new === 1 &&
                                                    <Sticker color="green" text="NOVO"/>
                                                }
                                                {item.total_discount &&
                                                    // <Sticker color="green" text={`${item.discount}%`} />
                                                    <Sticker color="red" text={`${item.total_discount}%`} />
                                                }
                                                { (item.free_shipping == 1 || parseInt(item.price) > 3000) &&
                                                    <Sticker color="blue" text="besplatna isporuka" paragraphId="free-shipping"/>
                                                }
                                                {item.preorder == 1 &&
                                                    <Sticker color="orange" text="Pretprodaja" fontSize="small"/>
                                                }
                                                {item.discounts && item.discounts.length > 0 &&
                                                    <Sticker image={item.discounts[0].image} />

                                                }
                                                {item.actionSticker  &&
                                                    <Sticker image={item.actionSticker} link={item.actionUrl}/>
                                                }
                                            </Fragment>
                                        }
                                       
                                        
                                        {/* <Sticker color="turqoise" text="multi"/> */}
                                    </div>
                                {/* } */}
                                <h1 className="product-right-title">{item.title}</h1>
                                <div className="product-authors">
                                    {item.authors.map(function(author, index){
                                            return(
                                                <span key={index} className="authors-span">
                                                    {author.name &&
                                                        <a href={this.authorNameLink(author.name)}><h3>{author.name}</h3></a>
                                                    }
                                                    {/* {!author.link &&
                                                        <h3>{author.name}</h3>
                                                    } */}
                                                </span>
                                            )}, this)
                                        }
                                        </div>
                                <StarRatings
                                    //rating={this.state.rating}
                                    //rating={item.rating}
                                    rating={item.rating}
                                    starDimension="1.6em"
                                    starSpacing=".2em"
                                    starRatedColor="#e6bc00"
                                    changeRating={this.changeRating}
                                    numberOfStars={5}
                                    name={item.id.toString()}
                                />
                                <div className="secondary-images-cont secondary-image-cont-mobile">
                                    {this.state.images.map(function(item, index){
                                        return(
                                            <div className='secondary-image-cont' key={index} onClick={this.changeImage} id={index + 1}>
                                                <img alt="" className='secondary-image' onClick={this.changeImage} id={index + 1} src={Service.secondaryImages + item.image_name} />
                                            </div>
                                        )}, this)
                                    }
                                </div>
                                <AddThis url={window.location.href} title={ 'Publik Praktikum | '+item.title } />
                                { this.state.pending === false &&
                                    <div className="product-container-info">
                                        { item.ilustrator &&
                                        <Info label="ilustrator" value={item.ilustrator} />
                                        }
                                        { item.translator &&
                                        <Info label="prevodilac" value={item.translator} />
                                        }
                                        
                                       { item.binding.length > 0 &&
                                        <Info label="povez" value={item.binding} />
                                       }
                                       { item.number_of_pages.length > 0 && parseInt(item.number_of_pages) != 0 &&
                                        <Info label="broj strana" value={item.number_of_pages} />
                                        }
                                       
                                       { item.letter.length > 0 &&
                                        <Info label="pismo" value={item.letter} />
                                        }
                                       { item.dimension.length > 0 &&
                                        <Info label="format" value={item.dimension} />
                                        }
                                       { item.isbn.length > 0 &&
                                        <Info label="isbn" value={item.isbn} />
                                       }
                                    </div>
                                }
                            
                            </div>
                            <div className="product-right">
                                { parseInt(item.status) === 1 && item.stock > 0 &&
                                <div>
                                    {item.full_price !== item.price &&
                                        <span>
                                            <p className="old-price">{Service.price(item.full_price)}</p>
                                            <h2>{Service.price(item.price)}</h2>

                                            
                                        </span>
                                    }
                                    {item.full_price === item.price &&
                                        <div>
                                            <h2>{Service.price(item.price)}</h2>
                                            
                                             
                                        </div>
                                    }
                                    
                                
                                
                                    <button type="button" className="add-to-cart-button product-add-to-cart" onClick={()=>{this.addToCart(item.id, item)}}>DODAJ U KORPU</button>
                                </div>
                                }
                                { (parseInt(item.status) === 3 || item.stock <= 0 ) &&
                                    <div>
                                        <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button>
                                        <p className="notify-me"><input type="checkbox" onChange={()=>{this.notifyMe(item.id)}} checked={this.state.notify} /> Obavesti me kad ovaj naslov bude dostupan.</p>
                                    </div>
                                }
                                { parseInt(item.status) === 2 &&
                                    <div>
                                        {/* <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button> */}
                                        <p>Ovaj naslov trenutno je<br />U PRIPREMI</p>
                                        <p className="notify-me"><input type="checkbox" onChange={()=>{this.notifyMe(item.id)}} checked={this.state.notify} /> Obavesti me kad ovaj naslov bude dostupan.</p>
                                    </div>
                                }

                                <button type="button" className="add-to-cart-button wishlist-button" onClick={()=>{this.addToWishlist(item)}}>DODAJ U LISTU ŽELJA</button>
                                <hr />
                                <p className="product-right-toggle" onClick={this.toggleHelp}>Potrebna vam je pomoć?</p>
                            
                                {/* Additional info */}
                                <div className="additional-info">
                                    <div className="additional-info-item">
                                        <img src='/images/2-povracaj-novca-purple.png' />
                                        <p>povraćaj novca</p>
                                    </div>
                                    <div className="additional-info-item">
                                        <img src='/images/2-sigurno-placanje-purple.png' />
                                        <p>sigurno plaćanje</p>
                                    </div>
                                    <div className="additional-info-item">
                                        <img src='/images/2-sigurna-dostava-purple.png' />
                                        <p>sigurna dostava</p>
                                    </div>
                                    <div className="additional-info-item">
                                        <img src='/images/2-placanje-karticom-purple.png' />
                                        <p>plaćanje karticom</p>
                                    </div>
                                </div>
                            </div>
                            
                        
                            <div className="secondary-images-cont secondary-image-cont-desktop">
                                    {this.state.images.map(function(item, index){
                                        return(
                                            <div className='secondary-image-cont' key={index} onClick={this.changeImage} id={index + 1}>
                                                <img alt="" className='secondary-image' onClick={this.changeImage} id={index + 1} src={Service.secondaryImages + item.image_name} />
                                            </div>
                                        )}, this)
                                    }
                            </div>
                            <div className="image-preview" style={{display: this.state.modalImagesVisible}} id='image-preview' onClick={this.hideImagePreview}>
                            <span className="popup-slider-cont" id='image-preview-body'>
                                <Slider ref={slider => (this.slider = slider)} initialSlide={this.state.selectedImage} {...settings}>
                                    {this.state.productImages.map(function(item, index){
                                        return(
                                        <img alt="" src={item} />
                                        )

                                    })

                                    }
                                </Slider>
                            </span>
                            </div>
                            
                          
                            <div className="product-bottom">
                                <h2>Opis:</h2>
                                    {renderHTML(item.description)}
                                    {/* <p style={{whiteSpace: 'pre-wrap'}}>{item.description}</p> */}
                                    {!!item.video &&
                                        <div className="product-video-cont">
                                            <iframe 
                                                src={item.video} allowfullscreen>
                                            </iframe>
                                        </div>
                                    }
                                    <hr />

                                <div>
                                    <h2>Iz iste oblasti</h2>
                                    <RelatedProducts products={this.state.relatedProducts} perPage={this.state.productsCount} />
                                </div>
                                <div className="comments">
                                    
                                    <h2>Komentari</h2>
                                    {this.state.comments.map(function(item, index){
                                            return(
                                                <div className='comment' key={index}>
                                                    {/* <h4>{item.name}</h4>
                                                    <h5>{item.created_at}</h5> */}
                                                    <p>{item.text}</p>
                                                    <h5>{item.name} <span>{item.created_at.slice(0,10)}</span></h5>
                                                </div>
                                            )
                                        },this)
                                    }
                                    
                                <textarea placeholder="Napišite komentar..." cols="60" rows="5" value={this.state.comment} onChange={this.handleComment} />
                                    <br/>
                                    <button type="button" className="add-to-cart-button" onClick={this.addComment}>Pošalji</button>
                                </div>
                            </div>
                            
                        </div>

                        
                    );
                    },this)
                  }
                    <div className="help-button" onClick={this.toggleHelp}>?</div>
                    <div className={this.state.helpClass}>
                        <div className="help-content">
                            <h3>Potrebna vam je pomoć?</h3>

                            <p>Ukoliko imate neki
                            problem pri kupovini
                            ili želite više informacija
                            kontaktirajte nas.</p>

                            <p>Pozovite nas<br />
                            011/4202544<br />
                            011/4202545</p>

                            Pišite nam<br />
                            office@publikpraktikum.rs
                        </div>
                    </div>
                </div>
                }
            </div>

        )
}

export default product_1;