import React, { Component, Fragment } from 'react';
import ProductSlider from '../components/ProductSlider';
import TopProductSlider from '../components/TopProductSlider';
import Service from '../components/Service';
import ImageSlider from '../components/ImageSlider';
import NewsSlider from '../components/NewsSlider';
import {Link} from 'react-router-dom';
import ProductView from '../components/ProductView';
import Banners from '../components/Banners';
import CentralBanner from '../components/CentralBanner';
import { Helmet } from 'react-helmet';

//import ReactGA from 'react-ga';
import Slider from '../components/Slider';
import Sticker from '../components/Sticker';



// ReactGA.initialize(Service.gaCode);
// ReactGA.pageview(window.location.pathname + window.location.search);


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // products: [],
            news: [],
            topProducts: [],
            newProducts: [],
            topKids: [],
            newProductsCount: 5,
            newsCount: 3
        }
    }

    getNewProducts=()=>{
        Service.get('/products/getNew',{},(res)=>{
            let productsCount = 0; // products cound if product slider has less then 5 products
            if(res.data.products.length < 5){
                productsCount = res.data.products.length;
            }else{
                productsCount = 5;
            }
            if(window.screen.width < 720){
                productsCount = 3;
            }
            this.setState({newProductsCount: productsCount});
            this.setState({newProducts: res.data.products});
        });
    }

    componentDidMount(){
        let listName = '';
        if(Service.storeId === '3'){
            listName = 'pp-top';
        }else if(Service.storeId === '1'){
            listName = 'hk-top';
        }else if(Service.storeId === '2'){
            listName = 'st-top';
        }
       // ReactGA.initialize(Service.gaCode);
       // ReactGA.pageview(window.location.pathname + window.location.search);

        this.getNewProducts();


        
        // VESTI
        Service.getNews((res) => {
            let newsCount = 0; // products cound if product slider has less then 5 products
            if(res.data.length < 3){
                newsCount = res.data.length;
            }else{
                newsCount = 3;
            }
            this.setState({newsCount: newsCount});
           this.setState({news: res.data.slice(0, 3)});
        });

        Service.getTopProducts({listName: listName},
            (res)=>{
                let productsCount = 0; // products cound if product slider has less then 5 products
                if(res.data.products.length < 5){
                    productsCount = res.data.products.length;
                }else{
                    productsCount = 5;
                }
                if(window.screen.width < 720){
                    productsCount = 3;
                }
                this.setState({topProductsCount: productsCount});
                this.setState({topProducts: res.data.products});
            }
        );


        // TOP DECJE KJIGE
        Service.getTopProducts({listName: 'pp-kids'},
            (res)=>{
                this.setState({topKids: res.data.products});
            }
        );

    }


  render() {
    return (
        <div className="home">
            <Helmet>
                <title>{Service.websiteTitleName} | Početna</title>
                {/* {window.location.origin === 'http://stelaknjige.rs' || window.location.origin === 'https://stelaknjige.rs' || window.location.origin === 'http://www.stelaknjige.rs' || window.location.origin === 'https://www.stelaknjige.rs' && */}
                    <meta name="facebook-domain-verification" content="p6ijr6cutgj32f9ona2xj1umcwm3lx" />
                {/* } */}
                
            </Helmet>
            {/* <Slider perPage={5} responsivePerPage={2} auto={true} duration={.4}>
                {this.state.topProducts.map(function(item, index){
                        return(
                            // <li key={index} style={{width: sizes[this.props.perPage]}}>
                            <ProductView item={item} key={index+'ps'} numbers={true}/>
                            // </li>
                            // <p key={index}>{item.title}</p>   
                        )
                    },this)
                    }
            </Slider> */}
            {/* <img alt="" className="home-image" src="https://publikpraktikum.rs/Uploads/silmarilion-baner.jpg" /> */}
            <Banners />
            <h2>Novi Naslovi<hr /></h2>
            <Slider perPage={5} responsivePerPage={2} auto={true} duration={.4}>
                {this.state.newProducts.map(function(item, index){
                     let stickerNew = false;
                        return(
                            <div className="new-products-container" key={index+'new'}> 
                                {/* Stickers */}
                                {item.categories.map(function(categories, index){
                                    return(
                                        <span key={index}>
                                            {categories.name === "Novi naslovi" &&
                                                <Fragment>{stickerNew = true}</Fragment>
                                            }       
                                        </span>
                                    )}, this)
                                }
                                
                                <ProductView item={item} key={index+'ps'} numbers={true} stickerNew={item.new == 0 ? false : true}/>
                            </div>
                        )
                    },this)
                    }
            </Slider>
            {/* <ProductSlider products={this.state.newProducts} perPage={this.state.topProductsCount} /> */}
            {Service.storeId === '3' &&
                <hr />
            }
            {/* {Service.storeId === '1' && */}
                {/* //  <div className="home-static-banner">
                //      <a href="/proizvod/1196/o-svemu-se-moze-pregovarati-herb-koen">
                //         <img style={{width: '100%'}} src="/images/banners/o-svemu-se-moze-pregovarati-banner.jpeg" />
                //     </a> 
                // </div> */}
                
            {/* } */}
            <CentralBanner />

            <div className="st-top-knjige-slider-container">
                <h2>Top lista<hr /></h2>
                <Slider perPage={5} responsivePerPage={2} auto={true} duration={.4} interval={4000}>
                    {this.state.topProducts.map(function(item, index){
                            let stickerNew = false;
                            return(
                                // <li key={index} style={{width: sizes[this.props.perPage]}}>
                                // <ProductView item={item} key={index+'ps'} numbers={true}/>
                                // </li>
                                // <p key={index}>{item.title}</p>
                                <div className="top-products-container" key={index+'tps'} >
                                    <div className="top-position" >{index+1}</div>
                                        {/* Stickers */}
                                        {item.categories.map(function(categories, index){
                                        return(
                                            <span key={index}>
                                                {categories.name === "Novi naslovi" &&
                                                    <Fragment>{stickerNew = true}</Fragment>
                                                }       
                                            </span>
                                        )}, this)
                                        }
                                        
                                        {/* <ProductView item={item} stickerNew={stickerNew} /> */}
                                        <ProductView item={item} stickerNew={item.new == 0 ? false : true} />
                                        
                                </div>   
                            )
                        },this)
                        }
                </Slider>
            </div>

            {/* <TopProductSlider products={this.state.topProducts} perPage={this.state.topProductsCount} /> */}
            <hr />
            {Service.storeId === '3' &&
                <Fragment>
                    <h2>Top lista dečije knjige<hr /></h2>
                    <Slider perPage={5} responsivePerPage={2} auto={true} duration={.4} interval={3500}>
                        {this.state.topKids.map(function(item, index){
                                let stickerNew = false;
                                return(
                                    // <li key={index} style={{width: sizes[this.props.perPage]}}>
                                    // <ProductView item={item} key={index+'ps'} numbers={true}/>
                                    // </li>
                                    // <p key={index}>{item.title}</p>
                                    <div className="top-products-container" key={index+'tpskids'} >
                                        <div className="top-position" >{index+1}</div>

                                        {/* Stickers */}
                                        {item.categories.map(function(categories, index){
                                            return(
                                                <span key={index}>
                                                    {categories.name === "Novi naslovi" &&
                                                        <Fragment>{stickerNew = true}</Fragment>
                                                    }       
                                                </span>
                                            )}, this)
                                        }

                                        {/* <ProductView item={item} stickerNew={stickerNew}/> */}
                                        <ProductView item={item} stickerNew={item.new == 0 ? false : true} />
                                    </div>   
                                )
                            },this)
                            }
                    </Slider>
                    {/* <TopProductSlider products={this.state.topKids} perPage={this.state.topProductsCount} /> */}
                    <hr />
                </Fragment>
            }

            {Service.storeId === '3' &&
                <h2><Link to="/vesti">Vesti</Link><hr /></h2>
            }
            {Service.storeId === '1' &&
                <h2>
                    <Link to="/zanimljivosti">Zanimljivosti</Link><hr />
                </h2>
            }
            <NewsSlider news={this.state.news} perPage={this.state.newsCount} />
        </div>
    );
  }

}

export default Home;