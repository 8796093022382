import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import Service from '../components/Service';

class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            subscribed: false
        }

    }

    handleSubscription = () => {
        let s = this.state.subscribed;
        s = !s;
        this.setState({
            subscribed: s
        });
    }

    render(){
        return(
            <div className="footer">
                {Service.storeId === '3' &&
                    <Fragment>
                        <div className="footer-top">
                            <img alt="" src="/logo.png" />
                            <h3>PUBLIK PRAKTIKUM</h3>
                            <p>DOBROVOLJAČKA 10/I, 11080 ZEMUN, SRBIJA +381 11 4202544 | +381 11 4202545</p>
                        </div>
                        <div className="footer-bottom-left">
                            <ul>
                                <li><Link to="/o-nama">O nama</Link></li>
                                <li><Link to="/kako-kupovati">Kako kupovati</Link></li>
                                <li><Link to="/pravila">Pravila korišćenja</Link></li>
                                <li><Link to="/dostava">Dostava</Link></li>
                                {/* <li><Link to="/krcko">Krckov klub</Link></li> */}
                                <li><Link to="/kontakt">Kontakt</Link></li>
                            </ul>
                            
                            <p>Copyright © Publik Praktikum | <Link to='/kako-kupovati' >Polisa privatnosti</Link></p>
                            <p>LEGO, the LEGO logo, the Brick and Knob configurations, the Minifigure, FRIENDS logo, NINJAGO and NEXO KNIGHTS are trademarks of the LEGO Group. © 2016 The LEGO Group. Produced by AMEET under license from the LEGO Group.</p>
                        </div>
                        {/* <div className="footer-bottom-right">
                            <p><input className="home-checkbox" type="checkbox" checked={this.state.subscribed} onClick={this.handleSubscription} />Prihvatam da primam email obaveštenja</p>
                            <input type="text" placeholder="vas email..." /><button> POŠALJI </button>
                        </div> */}

                        {/* bank card images */}
                        <div className="footer-logos">
                            <div>
                                <img alt="" src="/images/cards.png" />
                                <img alt="" src="/images/dina-card-logo.png" />
                                <img alt="" src="/images/american-express-logo.png" />
                            </div>
                            <div>
                                <a href="https://www.bancaintesa.rs/pocetna.1.html" target="_blank" rel="noopener noreferrer">
                                <img alt="" src="/images/bintesa.png" />
                                </a>
                            </div>
                            <div>
                                <a href="https://rs.visa.com/rs/rs-rs/protectedeverywhere/index.html" target="_blank" rel="noopener noreferrer">
                                    <img alt="" src="/images/learn1.jpg" />
                                </a>
                                <a href="https://www.mastercard.us/en-us.html" target="_blank" rel="noopener noreferrer">
                                    <img alt="" src="/images/learn2.jpg" />
                                </a>                   
                            </div>
                        </div>
                    </Fragment>
                }
                {Service.storeId === '1' && 
                    <Fragment>
                        <div className="footer-top">
                            <img alt="" src="/logo-sign.png" />
                            <h3>HARMONIJA</h3>
                            <p>DOBROVOLJAČKA 10/I, 11080 ZEMUN, SRBIJA +381 11 4202544 | +381 11 4202545</p>
                            <ul>
                                <li><Link to="/o-nama">O nama</Link></li>
                                <li><Link to="/kako-kupovati">Kako kupovati</Link></li>
                                <li><Link to="/pravila">Pravila korišćenja</Link></li>
                                <li><Link to="/dostava">Dostava</Link></li>
                                <li><Link to="/kontakt">Kontakt</Link></li>
                            </ul>
                            <p>Copyright © Publik Praktikum | <Link to='/kako-kupovati' >Polisa privatnosti</Link></p>
                        </div>
                        <div className="footer-bottom-left"></div>

                        {/* bank card images */}
                        <div className="footer-logos">
                            <div>
                                <img alt="" src="/images/cards.png" />
                                <img alt="" src="/images/dina-card-logo.png" />
                                <img alt="" src="/images/american-express-logo.png" />
                            </div>
                            <div>
                                <a href="https://www.bancaintesa.rs/pocetna.1.html">
                                <img alt="" src="/images/bintesa.png" />
                                </a>
                            </div>
                            <div>
                                <a href="https://rs.visa.com/rs/rs-rs/protectedeverywhere/index.html">
                                    <img alt="" src="/images/learn1.jpg" />
                                </a>
                                <a href="https://www.mastercard.us/en-us.html">
                                    <img alt="" src="/images/learn2.jpg" />
                                </a>
                            </div>
                        </div>
                    </Fragment>
                }

                {Service.storeId === '2' && 
                    <div>

                        <div className="footer-top">
                            <img className="footer-stela-pattern" alt="" src="/patern-stela.png" />
                            <img className="footer-stela-logo" alt="" src="/logo-stela.png" />
                            <ul>
                                <li><Link to="/o-nama">O nama</Link></li>
                                <li><Link to="/kako-kupovati">Kako kupovati</Link></li>
                                <li><Link to="/pravila">Pravila korišćenja</Link></li>
                                <li><Link to="/dostava">Dostava</Link></li>
                                <li><Link to="/kontakt">Kontakt</Link></li>
                            </ul>
                            <p>DOBROVOLJAČKA 10/I, 11080 ZEMUN, SRBIJA +381 11 4202544 | +381 11 4202545</p>
                            
                            {/* <p className="st-footer-social">Instagram: stela_knjige</p>
                            <p className="st-footer-social">Facebook: Stela</p> */}
                            <div className="social-footer-stela">
                                <a href="https://www.facebook.com/stelaknjige" target="_blank"><img src="/fb-icon-stela.png"/></a>
                                <a href="https://www.instagram.com/stela_knjige/" target="_blank"><img src="/instagram-icon-stela.png"/></a>
                                {/* <Link to="/kontakt" ><img src="/mail.png" /></Link> */}
                            </div>
                        </div>
                        {/* <div className="footer-bottom-left"></div> */}

                        {/* bank card images */}
                        <div className="footer-logos">
                            <div>
                                <img alt="" src="/images/cards.png" />
                                <img alt="" src="/images/dina-card-logo.png" />
                                <img alt="" src="/images/american-express-logo.png" />
                            </div>
                            <div>
                                <a href="https://www.bancaintesa.rs/pocetna.1.html">
                                <img alt="" src="/images/bintesa.png" />
                                </a>
                            </div>
                            <div>
                                <a href="https://rs.visa.com/rs/rs-rs/protectedeverywhere/index.html">
                                    <img alt="" src="/images/learn1.jpg" />
                                </a>
                                <a href="https://www.mastercard.us/en-us.html">
                                    <img alt="" src="/images/learn2.jpg" />
                                </a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Footer;