import React, { Component } from 'react';
import Service from '../components/Service';

import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';
import { Helmet } from 'react-helmet';




class About extends Component {


    
    render(){
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName} | O nama</title>
                </Helmet>

                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                    {/* <Categories /> */}
                </div>

                {Service.storeId === '3' &&
                    <div className="right-content">
                        <h1>
                            O nama
                        </h1>
                        <br />
                        <p>
                            Naš tim se sastoji od mladih i kreativnih ljudi koji se trude da čitaocima pruže kvalitetna i popularna dela svetske književnosti.
                        </p>
                        <br />
                        <p>
                            Jedan od ciljeva našeg izdavaštva je i podsticanje čitalačke kulture kod mladih i negovanje ljubavi prema knjizi. Otkupili smo prava i objavili romane koji su veoma aktuelni među mlađom populacijom, pre svega tinejdžerima. Među našim prevodima nalaze se najpoznatiji svetski autori.
                        </p>
                        <br />
                        <p>
                            U svojoj ponudi imamo i knjige za decu, koje kroz igru i zabavu podstiču razvoj opažanja, pamćenja i zaključivanja. Ponosni smo na činjenicu da smo stekli veliki krug svojih čitalaca.
                        </p>
                        <br /> 
                        <p>
                            Posebnu pažnju posvećujemo širenju školskog i predškolskog programa. Naše knjige i drugi nastavni materijali u potpunosti prate školski nastavni program.
                        </p>
                        <br />
                        <p>
                            Trudićemo se da u daljem radu pratimo najviše standarde i da na našem tržištu objavimo najkvalitetnije svetske hitove kako iz literature za decu i mlade tako i iz literature za odrasle.
                        </p>
                    </div>
                }

                {Service.storeId === '1' &&
                    <div className="right-content">
                        <h2>
                            O nama
                        </h2>
                        <br />
                        <p>
                            Harmonija svojim radom želi da pruži pomoć i nadahnuće svojim čitaocima na putu lične transformacije i postizanja zdravlja i blagostanja u svim područjima života. Objavljivanjem knjiga koje nude jedinstven pogled na zdravlje, sveopšte blagostanje, način života, ishranu i lični razvoj, kao i na odnose s drugim ljudima, roditeljstvo i duhovnost.
                        </p>
                        <br />
                        <p>
                            Harmonija daje podršku čitaocima u njihovoj želji da postanu najbolja moguća verzija sebe, kako spolja, tako i iznutra. 
                        </p>
                    </div>
                }

                {Service.storeId === '2' &&
                    <div className="right-content">
                        <h2>
                            O nama
                        </h2>
                        <p>
                            Stela knjige, novi imprint izdavačke kuće Publik praktikum, nastavlja u prepoznatljivom stilu pažljivog odabira naslova, a sada kroz književnu fikciju donosi najčitanije prevode kvalitetne literature, s težištem na najčitanijim bestselerima svih žanrova od svetski priznatih autora.
                        </p>
                        <p>
                            Ljubitelji naučne fantastike i fantazije, detektivskih priča i trilera, moderne i klasične literature, istorijske i ljubavne proze, u našoj biblioteci će pronaći teme koje ih zanimaju, bilo da se radi o distopiji, romansiranoj biografiji, istorijskoj priči, postapokalipsi, hororu, dobu burnih transformacija i prevrata, misteriji, životnoj drami, porodičnim odnosima, uzavrelim strastima ili mirnoj romantici.
                        </p>

                    </div>
                }
            </div>
        );
    }

}

export default About;