import React, { Component } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

const oc = {
  background: '#00000099',
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: '500',
  top: '0',
  left: '0',
  textAlign: 'center',
  paddingTop: '200px',
  color: 'white'

};


class Overlay extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        return(
            <span style={{display: this.props.visible == true ? 'block' : 'none'}} >
            <div style={oc}>
                <Loader
                    type="Oval"
                    color="#fff"
                    height={100}
                    width={100}
                    timeout={100000} //3 secs
                    //visible={this.state.loaderVisible}
                    visible={true}
                />
                <div>{this.props.text}</div>
            </div>
            </span>
        );
    }

}

export default Overlay;