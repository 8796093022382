import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import CartDisplay from './CartDisplay';
import CartDisplayMobile from './CartDisplayMobile';
import WishDisplay from './WishDisplay';
import Registration from './Registration';
import {withRouter} from 'react-router-dom';
import {Auth} from '../components/Auth';
import duix from 'duix';
import Service from '../components/Service';

let checkToken;

let stelaStars = [1,2,3,4,5,6,7,8,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];


class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            searchString: '',
            focused: false
        }

    }

    

    componentDidMount(){
        this.updateCartDisplay();
        // check access token
            checkToken = setInterval(()=>{
                if(!Auth.checkToken()){
                    duix.set('registration', 1);
                };
            }, 5000)
        //}
    }

    search = () => {
        this.props.history.push('/proizvodi/filter/' + this.state.searchString);

    }

    changeSearch = (event) => {
        this.setState({searchString: event.target.value});
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.search();
        }
    }

    updateCartDisplay=()=>{
        // ref function for updating the products count in the cartDisplay component
        //this.cartDisplay.current.numberOfCartProducts();
    }

    updateRegistration=()=>{
        // ref function for updating the products count in the cartDisplay component
        //this.registrationDisplay.current.update();
    }

    toggleMenu=()=> {
        let el = document.getElementById("menu-list");
        if(el.style.display === "none" || !el.style.display){
            el.style.display = "block";
        } else {
            el.style.display = "none";
        }
    }

    closeMenu=()=>{
        let el = document.getElementById("menu-list");
        el.style.display = "none";
    }

    render(){
        return(
            <div id="header">
                {/* Publikpraktikum */}
                {Service.storeId === '3' &&
                    <Fragment>
                        {window.screen.width > 720 &&
                            <span>
                                <div className="logo-cont">
                                    <Link to="/">
                                        <img alt="" id="logo" src="/pp-logo.png" />
                                    </Link>
                                </div>
                                <div className="header-right">
                                    <img className="lupa" onClick={this.search} src="/lupa.png" alt="lupa"/>
                                    {/* <span className="search">pretraga</span> */}
                                    <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search" placeholder="pretraga..." />
                                    <span className="cart-container">
                                        <Registration ref={this.registrationDisplay}/>
                                        <Link to="/lista-zelja"><WishDisplay /></Link>
                                        <Link to="/korpa"><CartDisplay /></Link>
                                    </span>
                                    <ul>
                                        <li>
                                            <Link to="/">NASLOVNA</Link>
                                        </li>
                                        <li>
                                            {/* <a href="/proizvodi">KNJIGE</a> */}
                                            <Link to="/proizvodi">KNJIGE</Link>
                                        </li>
                                        <li>
                                            {/* <a href="/u-pripremi">U PRIPREMI</a> */}
                                            <Link to="/u-pripremi">U PRIPREMI</Link>
                                        </li>
                                        <li>
                                            <Link to="/kako-kupovati">KAKO KUPOVATI</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/krcko">KRCKO</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/o-nama">O NAMA</Link>
                                        </li>
                                        
                                        <li>
                                            <Link to="/kontakt">KONTAKT</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/korpa"><CartDisplay /></Link>
                                        </li> */}
                                    </ul>
                                    <div className="social-header">
                                        <a href="https://www.facebook.com/publikpraktikum/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/></svg></a>
                                        <a href="https://www.instagram.com/publikpraktikum/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
                                        <a href="https://www.youtube.com/channel/UCQP8h998F4wNU2ImJrr8mwA" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a>
                                    </div>
                                </div>
                                </span>
                                }

                                {window.screen.width <= 720 &&
                                <span>
                                    <div className="header-cont-mobile">
                                        <div className="logo-cont-mobile" >
                                            <Link to='/' >
                                                <img alt="" id="logo" src="/logo.png" />
                                            </Link>
                                            <div className="mobile-buttons">
                                                <a href="/registracija" ><button><i class="fas fa-user-alt"></i></button></a>
                                                {/* <a href="/korpa" ><button><i class="fas fa-shopping-cart"></i></button></a> */}
                                                <a href="/korpa" ><button><CartDisplayMobile /></button></a>
                                                
                                                <button onClick={this.toggleMenu}><i class="fas fa-bars"/></button>
                                            </div>

                                        </div>

                                        <div className="header-bottom-mobile">  
                                            <div className="search-mobile">
                                                <img className="lupa-mobile" onClick={this.search} src="/lupa.png" alt="lupa"/>
                                                <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search-mobile" placeholder="Pretraži" />
                                            </div>
                                        </div>

                                        <div className="header-mid-mobile" id="menu-mobile">
                                            <ul id='menu-list'>
                                                    <li>
                                                        <a href="/">Naslovna</a>
                                                    </li>
                                                    <li>
                                                        <a href="/proizvodi">Knjige</a>
                                                    </li>
                                                    <li>
                                                        <a href="/u-pripremi">U pripremi</a>
                                                    </li>
                                                    <li>
                                                        <a href="/lista-zelja">Lista želja</a>
                                                    </li>
                                                    <li>
                                                        <a href="/kako-kupovati">Kako kupovati</a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="/krcko">Krcko</a>
                                                    </li> */}
                                                    <li>
                                                        <a href="/o-nama">O nama</a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="/zanimljivosti">Zanimljivosti</a>
                                                    </li> */}
                                                    <li>
                                                        <a href="/kontakt">Kontakt</a>
                                                    </li>
                                                <li>
                                                    <div className="social-header-mobile">
                                                        <a href="https://www.facebook.com/publikpraktikum/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square"></i></a>
                                                        <a href="https://www.instagram.com/publikpraktikum/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                                                        <a href="https://www.youtube.com/channel/UCQP8h998F4wNU2ImJrr8mwA" target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
                                                        

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>     
                                        
                                        
                                    </div>




                                {/* <div className="logo-cont">
                                    <Link to="/">
                                        <img alt="" id="logo" src="https://publikpraktikum.rs/templates/PublikPraktikum/skins/main/Banner/logo.png" />
                                    </Link>
                                </div>
                                <div className="header-right">
                                    <img className="lupa" onClick={this.search} src="/lupa.png" />
                                    
                                    <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search" placeholder="pretraga..." />
                                    <span className="cart-container">
                                        <Registration ref={this.registrationDisplay}/>
                                        <Link to="/lista-zelja"><WishDisplay /></Link>
                                        <Link to="/korpa"><CartDisplay ref={this.cartDisplay}/></Link>
                                    </span>
                                    <ul>
                                        <li>
                                            <Link to="/">NASLOVNA</Link>
                                        </li>
                                        <li>
                                            <Link to="/proizvodi">KNJIGE</Link>
                                        </li>
                                        <li>
                                            <Link to="/u-pripremi">U PRIPREMI</Link>
                                        </li>
                                        <li>
                                            <Link to="/kako-kupovati">KAKO KUPOVATI</Link>
                                        </li>
                                        <li>
                                            <Link to="/krcko">KRCKO</Link>
                                        </li>
                                        <li>
                                            <Link to="/o-nama">O NAMA</Link>
                                        </li>
                                        
                                        <li>
                                            <Link to="/kontakt">KONTAKT</Link>
                                        </li>
                                    </ul>
                                    <div className="social-header">
                                        <a href="https://www.facebook.com/publikpraktikum/" target="_blank" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/></svg></a>
                                        <a href="https://www.instagram.com/publikpraktikum/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
                                        <a href="https://www.youtube.com/channel/UCQP8h998F4wNU2ImJrr8mwA" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a>
                                    </div>
                                </div> */}
                            </span>
                        }
                    </Fragment>
                }
                
                {/* Harmonija knjige */}
                {Service.storeId === '1' && 
                    <Fragment>
                        <span id="header-desktop">
                            <div className="header-cont">
                                <div className="logo-cont" onClick={()=>{this.props.history.push('/')}}>
                                    <img alt="" id="logo" src="/hk-logo.png" />

                                </div>
                                <div className="header-right">
                                    <div className="search">
                                        <img className="lupa" onClick={this.search} src="/lupa.png" />
                                        {/* <span className="search">pretraga</span> */}
                                        <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search" placeholder="pretraga..." />
                                    </div>
                                    <span className="cart-container">
                                        <Registration ref={this.registrationDisplay}/>
                                        <Link to="/lista-zelja"><WishDisplay /></Link>
                                        <Link to="/korpa"><CartDisplay ref={this.cartDisplay}/></Link>
                                    </span>
                                </div>
                            </div>
                            <div className="header-cont2">
                                <div className="cont">
                                    <div className="header-bottom">
                                        <ul>
                                            <li>
                                                <Link to="/">Naslovna</Link>
                                            </li>
                                            <li>
                                                {/* <a href="/proizvodi">Knjige</a> */}
                                                <Link to="/proizvodi">Knjige</Link>
                                            </li>
                                            <li>
                                                {/* <a href="/u-pripremi">U pripremi</a> */}
                                                <Link to="/u-pripremi">U pripremi</Link>
                                            </li>
                                            <li>
                                                <Link to="/kako-kupovati">Kako kupovati</Link>
                                            </li>
                                            <li>
                                                <Link to="/o-nama">O nama</Link>
                                            </li>
                                            <li>
                                                <Link to="/zanimljivosti">Zanimljivosti</Link>
                                            </li>
                                            <li>
                                                <Link to="/kontakt">Kontakt</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/korpa"><CartDisplay /></Link>
                                            </li> */}
                                        </ul>
                                        <div className="social-header">
                                            <a href="https://www.facebook.com/harmonijaknjige/" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/></svg></a>
                                            <a href="https://www.instagram.com/harmonija_knjige/" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
                                            <Link to="/kontakt" ><img src="/mail.png" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>



                        <span id="header-mobile">
                            <div className="header-cont-mobile">
                                <div className="logo-cont-mobile" >
                                    <img alt="" id="logo" src="/logo-harmonija-m.png" onClick={()=>{this.props.history.push('/')}}/>
                                    
                                    <div className="mobile-buttons">
                                        <a href="/registracija" ><button><i class="fas fa-user-alt"></i></button></a>
                                        {/* <a href="/korpa" ><button><i class="fas fa-shopping-cart"></i></button></a> */}
                                        <a href="/korpa" ><button><CartDisplayMobile /></button></a>
                                        <button onClick={this.toggleMenu}><i class="fas fa-bars"/></button>
                                    </div>

                                </div>

                                <div className="header-bottom-mobile">  
                                    <div className="search-mobile">
                                        <img className="lupa-mobile" onClick={this.search} src="/lupa.png" />
                                        <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search-mobile" placeholder="Pretraži" />
                                    </div>
                                </div>

                                <div className="header-mid-mobile" id="menu-mobile">
                                    <ul id='menu-list' onClick={this.toggleMenu}>
                                        <li>
                                            <Link to="/">Naslovna</Link>
                                        </li>
                                        <li>
                                            {/* <a href="/proizvodi">Knjige</a> */}
                                            <Link to="/proizvodi">Knjige</Link>
                                        </li>
                                        <li>
                                            {/* <a href="/u-pripremi">U pripremi</a> */}
                                            <Link to="/u-pripremi">U pripremi</Link>
                                        </li>
                                        <li>
                                            <Link to="/lista-zelja">Lista želja</Link>
                                        </li>
                                        <li>
                                            <Link to="/kako-kupovati">Kako kupovati</Link>
                                        </li>
                                        <li>
                                            <Link to="/o-nama">O nama</Link>
                                        </li>
                                        <li>
                                            <Link to="/zanimljivosti">Zanimljivosti</Link>
                                        </li>
                                        <li>
                                            <Link to="/kontakt">Kontakt</Link>
                                        </li>
                                            {/* <li>
                                                <a href="/">Naslovna</a>
                                            </li>
                                            <li>
                                                <a href="/proizvodi">Knjige</a>
                                            </li>
                                            <li>
                                                <a href="/u-pripremi">U pripremi</a>
                                            </li>
                                            <li>
                                                <a href="/kako-kupovati">Kako kupovati</a>
                                            </li>
                                            <li>
                                                <a href="/o-nama">O nama</a>
                                            </li>
                                            <li>
                                                <a href="/zanimljivosti">Zanimljivosti</a>
                                            </li>
                                            <li>
                                                <a href="/kontakt">Kontakt</a>
                                            </li> */}
                                        <li>
                                            <div className="social-header-mobile">
                                                <a href="https://www.facebook.com/harmonijaknjige/" ><i class="fab fa-facebook-square"></i></a>
                                                <a href="https://www.instagram.com/harmonija_knjige/" ><i class="fab fa-instagram"></i></a>
                                                <a href="/kontakt" ><i class="far fa-envelope"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>     
                                
                                
                            </div>
                            
                        </span>
                    </Fragment>
                }


                {/* Stela */}
                {Service.storeId === '2' && 
                    <Fragment>
                        <span id="header-desktop">
                            <div className="header-stars-container">
                                {stelaStars.map(item => {
                                    return(
                                    <img src={window.location.origin + "/stela-star.svg"} className="header-star" style={{width: (Math.random() * 30 + 10) + 'px', top: Math.random() * 100 + '%', left: Math.random() * 100 + '%', opacity: (Math.random() * 50 + 20) / 100, animationDelay: Math.random() * 2 + 's'}}/>
                                    )
                                })}
                            </div>
                            <div className="header-cont">
                                <div className="logo-cont">
                                    <img className="stela-stars-bck" src="/images/stela-stars-bck.png" />
                                    <Link to="/"><img alt="" id="logo" src="/logo-stela.png" /></Link>
                                    <span className="cart-container">
                                        <Link to="/korpa"><CartDisplay ref={this.cartDisplay}/></Link>  
                                        <Link to="/lista-zelja"><WishDisplay />
                                        </Link><Registration ref={this.registrationDisplay}/>
                                    </span>

                                </div>
                                <div className="search">
                                    <img className="lupa" onClick={this.search} src="/lupa.png" />
                                    {/* <span className="search">pretraga</span> */}
                                    <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search" placeholder="pretraga..." />
                                </div>
                                   

                            </div>
                            <div className="header-cont2">
                                <div className="cont">
                                    <div className="header-bottom">
                                        <ul>
                                            <li>
                                                <Link to="/">Naslovna</Link>
                                            </li>
                                            <li>
                                                {/* <a href="/proizvodi">Knjige</a> */}
                                                <Link to="/proizvodi">Knjige</Link>
                                            </li>
                                            <li>
                                                {/* <a href="/u-pripremi">U pripremi</a> */}
                                                <Link to="/u-pripremi">U pripremi</Link>
                                            </li>
                                            <li>
                                                <Link to="/kako-kupovati">Kako kupovati</Link>
                                            </li>
                                            <li>
                                                <Link to="/o-nama">O nama</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/zanimljivosti">Zanimljivosti</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/kontakt">Kontakt</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/korpa"><CartDisplay /></Link>
                                            </li> */}
                                        </ul>
                                        {/* <div className="social-header">
                                            <a href="https://www.facebook.com/harmonijaknjige/" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/></svg></a>
                                            <a href="https://www.instagram.com/harmonija_knjige/" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
                                            <Link to="/kontakt" ><img src="/mail.png" /></Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </span>



                        <span id="header-mobile">
                            <div className="header-cont-mobile">
                                <div className="logo-cont-mobile" >
                                    <img alt="" id="logo" src="/logo-stela.png" onClick={()=>{this.props.history.push('/')}}/>
                                    
                                    <div className="mobile-buttons">
                                        <a href="/registracija" ><button><i class="fas fa-user-alt"></i></button></a>
                                        {/* <a href="/korpa" ><button><i class="fas fa-shopping-cart"></i></button></a> */}
                                        <Link to="/korpa" ><button><CartDisplayMobile /></button></Link>
                                        <button onClick={this.toggleMenu}><i class="fas fa-bars"/></button>
                                    </div>

                                </div>

                                <div className="header-bottom-mobile">  
                                    {/* <div className="search-mobile">
                                        <img className="lupa-mobile" onClick={this.search} src="/lupa.png" />
                                        <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search-mobile" placeholder="Pretraži" />
                                    </div> */}
                                    <div className="search">
                                        <img className="lupa" onClick={this.search} src="/lupa.png" />
                                        {/* <span className="search">pretraga</span> */}
                                        <input type="text" onKeyPress={this.handleKeyPress} onChange={this.changeSearch} value={this.state.searchString} className="search" placeholder="pretraga..." />
                                    </div>
                                </div>

                                <div className="header-mid-mobile" id="menu-mobile">
                                    <ul id='menu-list' onClick={this.toggleMenu}>
                                        <li>
                                            <Link to="/">Naslovna</Link>
                                        </li>
                                        <li>
                                            {/* <a href="/proizvodi">Knjige</a> */}
                                            <Link to="/proizvodi">Knjige</Link>
                                        </li>
                                        <li>
                                            {/* <a href="/u-pripremi">U pripremi</a> */}
                                            <Link to="/u-pripremi">U pripremi</Link>
                                        </li>
                                        <li>
                                            <Link to="/lista-zelja">Lista želja</Link>
                                        </li>
                                        <li>
                                            <Link to="/kako-kupovati">Kako kupovati</Link>
                                        </li>
                                        <li>
                                            <Link to="/o-nama">O nama</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/zanimljivosti">Zanimljivosti</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/kontakt">Kontakt</Link>
                                        </li>
                                          
                                        {/* <li>
                                            <div className="social-header-mobile">
                                                <a href="https://www.facebook.com/harmonijaknjige/" ><i class="fab fa-facebook-square"></i></a>
                                                <a href="https://www.instagram.com/harmonija_knjige/" ><i class="fab fa-instagram"></i></a>
                                                <a href="/kontakt" ><i class="far fa-envelope"></i></a>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>     
                                
                                
                            </div>
                            
                        </span>
                    </Fragment>
                }
            </div>
        )
    }

}


export default withRouter(props => <Header {...props}/>);
//export default Header;