import React, { Component } from 'react';
import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';
import Service from '../components/Service';
import { Helmet } from 'react-helmet';


class Howto extends Component {


   

    
    render(){
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName} | Kako kupovati</title>
                </Helmet>

                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                </div>
                <div className="right-content">
                    <h1>Kako kupovati</h1>
                    <p>
                        Da biste kupovali na našem sajtu nije neophodno da se registrujete. Ipak, preporučujemo svima da se 
                        registruju (klikom na dugme PRIJAVA u gornjem desnom uglu) jer će tako biti u toku sa svim našim novim 
                        izdanjima, popustima, akcijama, darivanjima i drugim lepim i zanimljivim stvarima.
                    </p>
                    <br />
                    <h3>
                        Način registracije
                    </h3>
                    <br />
                    <p>
                        Proces registracije je jednostavan:
                        <br />
                        <b>Korak 1</b>
                        : Kliknite na dugme "PRIJAVA".
                        <br />
                        <b>Korak 2</b>
                        : Da biste se uspešno registrovali, upišite tražene podatke u rubrike koje su za to predviđene.
                        <br />
                        <b>Korak 3</b>
                        : Kliknite na dugme "REGISTRUJ SE"
                        <br />
                        <b>Korak 4</b>
                        : Nakon što ste kliknuli na "REGISTRUJ SE", otvoriće vam se novi prozor sa obaveštenjem o uspešnom početku procesa registracije. 
                        <br />
                        <b>Korak 5</b>
                        : Molimo Vas da odmah otvorite sanduče svoje e-mail adrese i proverite korisničke podatke za logovanje. Ukoliko podaci nisu tamo, obavezno proverite u "Spam" ili "Junk" folderu. Kada ponovo budete posetili našu stranicu, biće dovoljno samo da se ulogujete.
                        <br />
                        <b>Korak 6</b>
                        : Kada primite poruku da je Vaš nalog verifikovan i aktivan, pritiskom na dugme "OK" izvršiće se logovanje. Možete se logovati koristeći Vaš e-mail i šifru koju ste uneli prilikom registracije. Na kraju pritisnite dugme "PRIJAVA".
                    </p>
                    <h3>
                        Kupovina
                    </h3>
                    <p>
                        Način kupovine:
                        <br />
                        <b>Korak 1</b>
                        : Prilikom kupovine prvo odaberite artikle koje želite da kupite. Preko glavnog menija možete lako pretraživati kategorije. Izborom neke od kategorija, otvoriće se nova stranica sa izlistanim proizvodima koji samo njoj pripadaju.
                        <br />
                        <b>Korak 2</b>
                        : Klikom na dugme "Dodaj u listu želja" artikal će automatski biti dodat u Vašu “LISTU ŽELJA”, a klikom na dugme "DODAJ U KORPU", artikal će automatski biti dodat u "KORPU" (gornji desni ugao ekrana).
                        <br />
                        <b>Korak 3</b>
                        : Sa svoje liste želja možete na isti način dodavati artikle i pratiti brojno stanje artikala u korpi i njihovu ukupnu cenu. Proizvode možete unositi u korpu dok ne naručite sve što želite. 
                        <br />
                        <b>Korak 4</b>
                        : Ukoliko želite da odustanete od kupovine nekog naslova kliknite na "KORPA" gde su ispisani naslovi koje želite da kupite. Pored cene kliknite na ikonicu za “kantu” ili “X” i naslov ce biti uklonjen iz korpe.
                        <br />
                        <b>Korak 5</b>
                        : Pošto ste odabrali sve artikle koje želite da kupite, kliknite na “KORPA“ u gornjem desnom uglu i otvoriće Vam se obrazac za kupovinu.
                        {/* <br />
                            *Ukoliko želite da nastavite sa kupovinom i dodate još neki artikl u korpu kliknite na dugme "NASTAVI KUPOVINU". */}
                    </p>
                    <h3>
                        Kompletiranje porudžbenice
                    </h3>
                    <p>
                        <b>Korak 1 - Sadržaj korpe</b>
                    </p>
                        <ul>
                            <li>
                                Pregledajte svoju porudžbinu. 
                            </li>
                            <li>
                                Ukoliko želite možete odustati od kupovine nekog naslova klikom na „X“ ili ikonice za “kantu” na kraju njegove linije ili izmeniti broj poručenih primeraka klikom na “+” i “-“ (minus).
                            </li>
                            <li>
                                Možete se vratiti na kupovinu klikom na polje KNJIGE.
                            </li>
                            <li>
                                Ako su svi podaci u korpi tačni, idite na korak 2.
                            </li>
                        </ul>
                        <b>Korak 2 – Podaci za isporuku</b>
                        <ul>
                            <li>
                                Unesite ili izmenite ime i prezime, adresu na koju ćemo poslati knjige i ostale podatke iz rubrika. 
                                Ukoliko su adresa za isporuku drugačija od adrese plaćanja (npr. naručujete knjige za nekog drugog i 
                                šaljemo na njegovu adresu) onda štrikirate polje “<b>Adresa isporuke</b> je drugačija od adrese plaćanja?” i 
                                unesete drugu adresu za isporuku.
                            </li>
                            <li>
                                Ako su svi podaci uneti, idite na korak 3.
                            </li>
                        </ul>
                        <b>Korak 3 – Način isporuke i plaćanja</b>
                        <ul>
                            <li>
                                Ovde ćete videti koliko iznose troškovi isporuke Vaše porudžbenice. Cena je sa PDV-om.
                            </li>
                            <li>
                                Izaberite način plaćanja:
                            </li>
                           
                        </ul>
                        <ol>
                            <li>
                                Štrikiranjem polja <b>PLATNOM KARTICOM</b>, obavićete plaćanje online pomoću sigurnog sistema banke “Intesa”.
                            </li>
                            <li>
                                Štrikiranjem polja <b>POUZEĆEM</b>, plaćanje se vrši prilikom preuzimanja pošiljke i plaćate kuriru.
                            </li>
                            <p>
                                <b> Prilikom dostave ne postoje dodatni troškovi za plaćanje kurirskoj službi u odnosu na one koji su Vam dostavljeni potvrdom na e-mail adresu (iznos knjige/a + iznos poštarine). Poštarina je uvek unapred uračunata u ukupni iznos porudžbenice (za pakete ispod 3000 dinara).</b>
                            </p>
                            <p>
                                <b>Primer:</b> cena knjige od 430 dinara + poštarina od 200 dinara za kupovinu karticom daju ukupan iznos za uplatu od 630 dinara (iznos koji dobijate potvrdom na e-mail adresu) i nije potrebno doplaćivanje prilikom isporuke za dodatnu poštarinu.
                            </p>
                            <p>Ukoliko ste izabrali tip plaćanja, idite na korak 4.</p>
                        </ol>
                           
                        <b>Korak 4 – Prihvatanje uslova kupovine</b>
                        <ul>
                            <li>
                                Prihvatite uslove kupovine i završavate kupovinu klikom na dugme “NARUČI”.
                            </li>
                            <li>
                                Prihvatanje uslova kupovine je obavezno i bez prihvatanja nije moguće završiti proces kupovine i zaključivanja narudžbenice.
                            </li>
                        </ul>
  
                    <p>
                        Ukoliko ste potvrdili porudžbenicu, na Vaš mejl će stici potvrda sa specifikacijom naručenih knjga, cenama, troškovima isporuke i rokom za dostavu. Ukoliko ne dobijete potvrdu sa brojem porudžbine na e-mail adresu, molimo da proverite “junk” i/ili “spam” foldere. U slučaju da ni nakon provere niste dobili potvrdu, molimo da nas kontaktirate na broj telefona 011/420-25-44. 
                    </p>

                    <p>
                        <span>Napomena</span>
                        : Narudžbina kupca ne predstavlja završenu narudžbinu, već je samo poziv da se učini ponuda koju PUBLIK PRAKTIKUM može da prihvati ili odbije sa osnovanim razlogom (npr. nedostatak robe na lageru). Sve cene su izražene u dinarima (RSD), a u cene proizvoda je uračunat PDV i nema dodatnih troškova.
                    </p>

                    <p>
                        <b>Lista želja:</b>
                    </p>

                    <ul>
                        <li>
                            Svoju listu želja pravite svakim klikom na dugme "Dodaj u listu želja" koje se nalazi uz svaki artikal.
                        </li>
                        <li>
                            Klikom na dugme Lista želja možete u svakom trenutku imati pristup svojoj listi želja koja se nalazi unutar Vaših korisničkih stranica i pregledati artikle koje ste odabrali.
                        </li>
                        <li>
                            Ukoliko Vam se neki proizvod svidi, ali još niste sasvim sigurni da li želite da ga kupite, dodajte ga na listu želja! 
                        </li>
                    </ul>

                    <p>
                        <span>Napomena</span>
                        : Lista želja Vam služi kao podsetnik i ne podrazumeva obaveznu kupovinu artikala koje ste odabrali. Pomoću nje možete na jednom mestu da vidite artikle koji Vas zanimaju i koje biste voleli da kupite.
                    </p>

                    <p>
                        <b>Naručivanje robe putem telefona</b>
                        <br />
                        Ukoliko iz nekog razloga niste u mogućnosti da napravite narudžbinu putem sajta, možete to učiniti i pozivom na neki od sledeća dva broja: 011/4202544 ili 011/4202545, radnim danima od 08 do 16 časova i subotom od 09 do 13 časova.
                    </p>

                    <h3>
                        Modeli plaćanja:
                    </h3>

                    <p>
                        <b>Karticom</b>
                        : Nakon zaključivanja narudžbenice bićete preusmereni na sigurni portal Banke Intese za plaćanje karticama. Ovo obaveštenje će Vam se pojaviti na vrhu stranice kod poručenih naslova. Kliknite na plavo polje „Platiti narudžbenicu“ i pratite uputstva banke.
                        <br />
                        <b>Pouzećem</b>
                        : Naručene artikle dobijate na kućnu adresu, a troškove narudžbine plaćate kuriru.
                    </p>

                    <h3>
                        Zaštita poverljivih podataka o transakciji
                    </h3>

                    <p>
                        Prilikom unošenja podataka o platnoj kartici poverljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije. Sigurnost podataka prilikom kupovine garantuje procesor platnih kartica, Banca Intesa ad Beograd, pa se tako kompletni proces naplate obavlja na stranicama banke. Nijednog trenutka podaci o Vašoj platnoj kartici nisu dostupni našem sistemu.
                    </p>

                    <h3>
                        Troškovi isporuke i načini dostave:
                    </h3>

                    <p>
                    Knjige je trenutno moguće slati jedino na teritoriji Republike Srbije.
                    </p>
                    <h3> Troškovi dostave:</h3>
                    <p>
                        Za naručene knjige u vrednosti do 3000,00 dinara troškovi za kupovinu putem kartice iznose 200,00 dinara, a troškovi dostave za kupovinu pouzećem iznose 300,00, dinara. 
                        Za porudžbine preko 3000,00 dinara dostava je besplatna.
                    </p>

                    <h3>
                        Procena roka za isporuku robe
                    </h3>
                    <p>
                        U Vašem i našem interesu jeste da se isporuka izvrši u najbržem roku. Porudžbine naručene radnim danima do 14h će biti poslate istog dana. Porudžbine naručene radnim danima nakon 14h će biti poslate sledećeg radnog dana. Porudžbine naručene petkom nakon 14h i vikendom, biće poslate prvog sledećeg radnog dana, odnosno u ponedeljak. Kurirske službe subotom i nedeljom ne preuzimaju i ne isporučuju pošiljke.
                    </p>

                    <h3>
                        Porudžbine iz Srbije
                    </h3>

                    <p>
                        Isporuka kurirskom službom vrši se u roku od 2 do 5 <b>radnih dana</b> od dana porudžbine, u zavisnosti od mesta isporuke.
                    </p>

                    <h3>
                        Povraćaj robe
                    </h3>

                    <p>
                        Samo u slučaju reklamacije može se izvršiti povraćaj robe.
                    </p>

                    <h3>
                        Reklamacije
                    </h3>

                    <p>
                        Reklamacije se mogu podneti isključivo u sledećim slučajevima:
                        
                    </p>
                        <ul>
                            <li>
                                Kada je isporučeni proizvod oštećen
                            </li>
                            <li>
                                Kada je nekompletan (isporuka robe u količinama koja nije naručena)
                            </li>
                            <li>
                                Kada ne odgovara specifikaciji narudžbine.
                            </li>
                        </ul>

                    <p>
                        Molimo Vas da prilikom preuzimanja pošiljke u prisustvu kurira proverite svoj paket da li ima vidnih fizičkih oštećenja. Ukoliko oštećenja postoje (pocepani delovi i ugnječenja) ne bi trebalo da preuzimate paket. Stoga Vas molimo da u ovom slučaju prijavite reklamaciju pozivom na jedan od brojeva: 011/4202544 ili 011/4202545 ili da nam pošaljete e-mail na office@publikpraktikum.rs sa svojim podacima i navedete razlog zbog kojeg ste odbili da preuzmete paket. O daljem postupanju bićete obavešteni u najkraćem mogućem roku.  
                    </p>

                    <p>
                        <strong>
                            U slučaju da kupac zeli da vrati robu koja je plaćena pouzećem, potrebno je da nam knjige vrati o svom trošku u neoštećenom stanju, a mi ćemo iznos za vraćenu robu uplatiti na tekući račun kupca.
                        </strong>
                    </p>

                    <p>
                    U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, PUBLIK PRAKTIKUM je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice.
                    </p>

                    <p>
                        <span>Rok za reklamaciju</span>
                        : Ukoliko ste primili pošiljku i nakon otvaranja kutije ustanovili da naručena roba ne odgovara naručenoj molimo Vas da u periodu od 24h prijavite reklamaciju.
                    </p>

                    <h2>
                        PRAVILA KORIŠĆENJA
                    </h2>

                    <h3>
                        Kontakt
                    </h3>

                    <p>
                        Ako budete imali bilo kakvih pitanja ili pritužbi koje se tiču privatnosti i sigurnosti Vaših podataka, ili ukoliko imate saznanja ili sumnju o narušavanju privatnosti ili sigurnosti, molimo Vas da nas kontaktirate na neki od sledećih načina:
                    </p>
                        <ol>
                            <li>
                                Pozovite nas na jedan od sledećih brojeva telefona: 011/42-02-544 ili 011/42-02-545
                            </li>
                            <li>
                                Pošaljite e-mail na: office@publikpraktikum.rs
                            </li>
                            <li>
                                Pošaljite pismo na poštansku adresu: Dobrovoljačka 10/I, 11080 Zemun
                            </li>
                        </ol>
                    

                    <p>
                        Pun naziv pravnog subjekta:
                        <b> PUBLIK PRAKTIKUM  DRUŠTVO ZA IZDAVAŠTVO, PROIZVODNJU, PROMET I USLUGE DOO BEOGRAD (ZEMUN)</b>
                        <br />
                        Sedište:
                        <b> Zemun, Dobrovoljačka 10/I</b>
                        <br />
                        Delatnost i šifra delatnosti:
                        <b> 5811-Izdavanje knjiga</b>
                        <br />
                        Matični broj:
                        <b> 17591967</b>
                        <br />
                        Poreski broj:
                        <b> 103635212</b>
                    </p>

                    <p>
                        Adresa za slanje pošte:
                        <b> Dobrovoljačka 10/I, 11080 Zemun</b>
                        <br />
                        Kontakt telefon:
                        <b> +381 11 4202 544 </b>
                        <br />
                        Kontakt e-mail:
                        <b> office@publikpraktikum.rs</b>
                        <br />
                        Web adresa:
                        <b> publikpraktikum.rs</b>
                    </p>


                </div>
            </div>
        );
    }

}

export default Howto;