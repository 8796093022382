import { Auth } from '../components/Auth';

var api = 'https://api.pp.cekaonica.com/api';
var images = 'https://api.pp.cekaonica.com/media/';
var paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
var gaCode = 'UA-000000-01';
var secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
var storeId = '';
var websiteTitleName = '';
var appVersion = '1.0.2';
if(window.location.origin === 'http://localhost:3001'){
    api = 'https://api.pp.cekaonica.com/api';
    images = 'https://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
    storeId = '3';
    websiteTitleName = 'Publik Praktikum';
}
if(window.location.origin === 'http://pp.cekaonica.com' || window.location.origin === 'https://pp.cekaonica.com'){
    api = 'https://api.pp.cekaonica.com/api';
    images = 'https://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
    storeId = '3';
    websiteTitleName = 'Publik Praktikum';
}

if(window.location.origin === 'http://publikpraktikum.cekaonica.com' || window.location.origin === 'https://publikpraktikum.cekaonica.com'){
    api = 'https://api.publikpraktikum.cekaonica.com/api';
    images = 'https://api.publikpraktikum.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'http://api.publikpraktikum.cekaonica.com/secondary-images/';
    storeId = '3';
    websiteTitleName = 'Publik Praktikum';
}

if(window.location.origin === 'http://publikpraktikum.rs' || window.location.origin === 'https://publikpraktikum.rs' || window.location.origin === 'http://www.publikpraktikum.rs' || window.location.origin === 'https://www.publikpraktikum.rs'){
    api = 'https://api.publikpraktikum.com/api';
    images = 'https://api.publikpraktikum.com/media/';
    paymentUrl = 'https://bib.eway2pay.com/fim/est3Dgate';
    gaCode = 'UA-80705254-1';
    secondaryImages = 'http://api.publikpraktikum.com/secondary-images/';
    storeId = '3';
    websiteTitleName = 'Publik Praktikum';
}

if(window.location.origin == 'http://localhost:3000'){
    api = 'https://api.pp.cekaonica.com/api';
    images = 'https://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
    storeId = '1';
    websiteTitleName = 'Harmonija knjige';
}
if(window.location.origin== 'http://hk.pp.cekaonica.com' || window.location.origin== 'https://hk.pp.cekaonica.com'){
    api = 'https://api.pp.cekaonica.com/api';
    images = 'https://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
    storeId = '1';
    websiteTitleName = 'Harmonija knjige';
}

if(window.location.origin== 'http://harmonijaknjige.cekaonica.com' || window.location.origin== 'https://harmonijaknjige.cekaonica.com'){
    api = 'https://api.publikpraktikum.cekaonica.com/api';
    images = 'https://api.publikpraktikum.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'http://api.publikpraktikum.cekaonica.com/secondary-images/';
    storeId = '1';
    websiteTitleName = 'Harmonija knjige';
}

if(window.location.origin == 'http://harmonijaknjige.rs' || window.location.origin == 'https://harmonijaknjige.rs' || window.location.origin == 'https://www.harmonijaknjige.rs' || window.location.origin == 'http://www.harmonijaknjige.rs'){
    api = 'https://api.publikpraktikum.com/api';
    images = 'https://api.publikpraktikum.com/media/';
    paymentUrl = 'https://bib.eway2pay.com/fim/est3Dgate';
    gaCode = 'UA-128585135-1';
    secondaryImages = 'http://api.publikpraktikum.com/secondary-images/';
    storeId = '1';
    websiteTitleName = 'Harmonija knjige';
}

if(window.location.origin == 'http://localhost:3002'){
    api = 'https://api.pp.cekaonica.com/api';
    images = 'https://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
    storeId = '2';
    websiteTitleName = 'Stela';
}

if(window.location.origin === 'http://st.pp.cekaonica.com' || window.location.origin === 'https://st.pp.cekaonica.com'){
    api = 'https://api.pp.cekaonica.com/api';
    images = 'https://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'https://api.pp.cekaonica.com/secondary-images/';
    storeId = '2';
    websiteTitleName = 'Stela';
}

if(window.location.origin === 'http://stelaknjige.rs' || window.location.origin === 'https://stelaknjige.rs' || window.location.origin === 'http://www.stelaknjige.rs' || window.location.origin === 'https://www.stelaknjige.rs'){
    api = 'https://api.publikpraktikum.com/api';
    images = 'https://api.publikpraktikum.com/media/';
    paymentUrl = 'https://bib.eway2pay.com/fim/est3Dgate';
    gaCode = 'UA-80705254-1';
    secondaryImages = 'http://api.publikpraktikum.com/secondary-images/';
    storeId = '2';
    websiteTitleName = 'Stela';
}


const Service = {

    paymentUrl: paymentUrl,
    gaCode: gaCode,
    secondaryImages: secondaryImages,
    shippingFee: 150,
    storeId: storeId,
    websiteTitleName: websiteTitleName,
    
    images(image){
        return images + image;
    },

    get(url, params, cb=null){
        params.Auth = Auth;

        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(result.app_version){
                    if(result.app_version != appVersion){
                        console.log('updating app to new version');
                        window.location.reload(true);
                    }
                }
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    price(price){
        if(price){
            let p = '';
            if(Number.isInteger(price)){
                let parsedPrice = new Intl.NumberFormat('de-DE', {currency: 'EUR' }).format(price);
                p = parsedPrice + ",00";
            }else{
                let arr = price.toString().split('.');

                if(arr[1].length < 2){
                    arr[1] += '0';
                }

                let parsedPrice = new Intl.NumberFormat('de-DE', {currency: 'EUR' }).format(arr[0]);
                p = parsedPrice + ',' + arr[1];
            }
            return p + ' RSD';
        }
        return '0,00 RSD';
    },

    getProducts(cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 20,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getPendingProducts(cb=null){
        fetch(`${api}/products/getPending`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 50,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getTopProducts(params,cb=null){
        fetch(`${api}/products/top`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
              if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
          )
    },

    getProductById(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getProductBySearch(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewProducts(cb=null){
        fetch(`${api}/products/getnewproductreleases`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    
    addToWishlist(params,cb=null){
        fetch(`${api}/users/addProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    removeFromWishList(params,cb=null){
        fetch(`${api}/users/removeProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getWishlist(params,cb=null){
        fetch(`${api}/users/getProducts`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getProductsByCategory(params, cb=null){

        fetch(`${api}/products/getByCategory`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(
              params
            )
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    register(email, password, name, subscribe, cb=null) {

        // return;
        fetch(`${api}/users/create`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
                name: name,
                subscribe: subscribe,
                email: email,
                password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    login(email, password, cb=null) {
        fetch(`${api}/users/login`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              email: email,
              password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }

            },
            (error) => {

            }
        )
    },

    createOrder(params,cb=null) {

        fetch(`${api}/orders/create`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    addComment(params,cb=null){
        fetch(`${api}/products/addComment`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getComments(params,cb=null){
        fetch(`${api}/products/getComments`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNews(cb=null){
        fetch(`${api}/news/all`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewsSingle(params,cb=null){
        fetch(`${api}/news/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    krckoSubscribe(params,cb=null){
        fetch(`${api}/krcko/subscribe`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

      getCart(params,cb=null){

        fetch(`${api}/orders/getCart`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    rateProduct(params,cb=null){

        fetch(`${api}/products/rate`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    }

 
}

export default Service;