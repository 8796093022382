import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import { Helmet } from 'react-helmet';

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: []
        }
    }

    componentDidMount(){
        if(this.props.match.params.hash){
            this.getOrder();
        }
    }
   
    getOrder=()=>{
        Service.get('/orders/get',{hash: this.props.match.params.hash},(res)=>{
            this.setState({order: res.orders[0]})
        });
    }
    
    render(){
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName} | Poružbina</title>
                </Helmet>

                <h1>Porudžbina #{this.state.order.id}</h1>

                <p>Status: {this.state.order.statusText ? this.state.order.statusText : 'Pakovanje'}</p>
                <p>Kod: {this.state.order.code}</p>
                <p>Datum: {this.state.order.created_at}</p>

                <div className="order-info-left">
                    <h3>Adresa plaćanja</h3>
                    Ime i prezime: {this.state.order.name}<br/>
                    Email: {this.state.order.email}<br/>
                    Broj telefona: {this.state.order.phone}<br/>

                    Adresa plaćanja: {this.state.order.street + " " + this.state.order.street_number}<br/>
                    Grad: {this.state.order.city + " " + this.state.order.zip_code}<br/>
                    Opština: {this.state.order.municipality}<br/>
                    Država: {this.state.order.country}<br/>
                </div>

                <div className="order-info-right">
                    <h3>Adresa isporuke</h3>
                    Ime i prezime isporuke: {this.state.order.shipping_name}<br/>
                    Email: {this.state.order.shipping_email}<br/>
                    Broj telefona: {this.state.order.shipping_phone}<br/>
                    Adresa isporuke: {this.state.order.shipping_street+ " " + this.state.order.shipping_street_number}<br/>
                    Grad: {this.state.order.shipping_city + " " + this.state.order.shipping_zip_code}<br/>
                    Opština: {this.state.order.shipping_municipality}<br/>
                    Država: {this.state.order.shipping_country}<br/>
                </div>

                {this.state.order.products && this.state.order.products.length > 0 &&
                    <Fragment>
                        <h2>Proizvodi</h2>
                        <table className="success-table">
                            <tr>
                                <td>Naslov</td>
                                <td>Cena</td>
                                <td>Količina</td>
                                <td>Ukupno</td>
                            </tr>
                        
                            {this.state.order.products.map(function(item, index){
                                return(
                                    <tr>
                                        <td>{item.title}</td>
                                        <td>{Service.price(item.unit_price)}</td>
                                        <td>{item.quantity} KOM.</td>
                                        <td>{Service.price(item.price)}</td>
                                    </tr>
                                )
                            },this)}
                        </table>
                    </Fragment>
                }
                <p>Isporuka: {Service.price(this.state.order.shipping_price)}</p>
                <p>Ukupno: {Service.price(this.state.order.total)}</p>
                <p>Način plaćanja: <b>{this.state.order.paymentMethod === 'cc' ? 'Platnom karticom' : 'Pouzećem'}</b></p>

            </div>
        );
    }

}

export default Order;