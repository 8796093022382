import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class ImageSlider extends Component {

linkTo = (index) => {

    let links = [
        '/proizvodi/kategorije/193/Montesori%20knjige',
        '/vest/53',
        '/proizvodi/kategorije/789/Dobre%20knjige%20Dobro%20zdravlje',
        '/proizvodi/kategorije/57/Radne%20sveske%20za%20pred%C5%A1kolce',
        '/proizvod/1085/upoznajem-zivotinje-sveta-magnetna-tabla',
        '/proizvodi/kategorije/52/Bajke',
        '/proizvodi/kategorije/781/Tolkin',
        '/proizvod/1013/silmarilion-dz-r-r-tolkin',
        '/proizvodi/kategorije/193/Montesori%20knjige',
        '/proizvod/528/neznani-sanjar-lejni-tejlor',
        '/proizvod/196/tri-najbolje-metode-ranog-razvoja',
        '/proizvodi/filter/klub%20detektiva'
    ];

    window.location.href = links[index];
}

    render() {
        return (
            <Carousel showThumbs={false} interval={6000} infiniteLoop={true} autoPlay={true} onClickItem={(e)=>{this.linkTo(e)}}>
                <div>
                    <img src="montesori-baner.jpg" alt="montesori" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="banner-krcko-maj.png" alt="akcija krcko maj" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="akcija-baner-publikpraktikum.jpg" alt="akcija baner publik praktikum" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="baner-otkrivam-stvaram-smisljam.jpg" alt="otkrivam stvaram smišljam" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="00-baner-za-sajt-Zivotinje-sveta.jpg" alt="životinje sveta" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="bajke-i-basne-banner.jpg" alt="bajke i basne" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                 <div>
                    <img src="gospodari-bros-baner.jpg" alt="gospodari" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="silmarilion-baner.jpg" alt="silmarilion" />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="montesori-radne-sveske.jpg"  alt="montesori radne sveske"/>
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src="neznani-sanjar-baner novo.jpg" alt="neznani sanjar" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>        
                <div>
                    <img src="3-metode-baner.jpg" alt="tri metode" />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <img src="klub-detektiva-baner.jpg" alt="klub detektiva" />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
        );
    }
};

export default ImageSlider;