import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import {Link} from 'react-router-dom';
import {Cart} from '../components/Cart';
import SajamText from '../components/SajamText';
import Shipping from '../components/Shipping';
import InputMask from 'react-input-mask';
import Selector from '../components/DexSelector';
import duix from 'duix';
import Overlay from '../components/Overlay';
import { Helmet } from 'react-helmet';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

var validator = require("email-validator");

function priceToNumber(n){
    let p = n.split(',');
    return p;
}

function parseStreetNumber(num){
    let tn = num.replace(/[^0-9a-zA-Z_\/ ŠĐŽĆČŽšđčćž\s]/g,'');

    if(!(/[bB0-9]/).test(tn.charAt(0)) ){
        tn = '';
    }

    // allow bb
    if(tn.charAt(0) == 'B' || tn.charAt(0) == 'b'){
        if(tn.length > 1){
            tn = 'bb';
        }
    }

    //remove all spaces
    var reg = new RegExp("[ ]+","g");
    tn = tn.replace(reg, "");

    return tn;
}

// function regexInput(input, regex){
//     return input.replace(regex, '');
// }

function parseDiscountName(name){
    return name.split(' ').join('-').toLowerCase();
}

const Discounts = (props) => {
  return (
    <div className="cart-item-discounts">
      {props.discounts.map((item, index)=>{
          return(
            <Link to={'/proizvodi/kategorije/ds_'+item.id+'/'+parseDiscountName(item.description)} key={'dis_'+index}><img src={item.image}></img></Link>
          )
      })
      
      }
    </div>
  );
};
class CartPage extends Component {

constructor(props){
    super(props);
    this.diffShipping = React.createRef()
    this.state = {
        cartItems: [],
        total: 0,
        subtotal: 0,
        shippingPrice: 0,
        name: '',
        nameError: 'none',
        address: '',
        addressError: 'none',
        city: '',
        cityError: 'none',
        zipCode: '',
        zipCodeError: 'none',
        email: '',
        emailError: 'none',
        municipality: '',
        telephone: '',
        telephoneDisplay: '',
        telephoneError: 'none',
        telephoneFormatError: 'block',
        productsError: 'none',
        country: 'Srbija',
        paymentMethod: 'cc',
        paymentMethodError: 'none',
        inputErrors: 'none',
        clientId: "13IN060311",
        orderId: '',
        amount: '',
        okUrl: "",
        failUrl: "",
        transactionType: "Auth",
        rnd: '',
        currency: "941",
        shopUrl: '',
        hashAlgorithm: '',
        storeType: '',
        encoding: '',
        hash: '',
        loaderVisible: false,
        source:'website',
        admin: false,
        note: '',
        hasShippingAddress: false,
        townCheckbox: true,
        shippingData: {},
        dStreet:'',
        dStreetNumber:'',
        numberError: 'none',
        dCity:'',
        dTown:'',
        dZipCode:'',
        dTownLabel:'',
        dNameError:'none',
        dStreetError:'none',
        dCityError:'none',
        dStreetNumberError:'none',
        dTelephoneError:'none',
        rTownId:'',
        checkVisible: false,
        coupon: '',
        coupons: [],
        appliedCoupons: [{name: 'vojno', description: 'test', discount: 20}],
        discount: 0,
        products: [],
        isActiveCoupon: false
    }
    
    this.formRef = React.createRef();
}

executeScroll = () => this.diffShipping.current.scrollIntoView({behavior: "smooth"})

componentDidMount(){
    // this.updateCart();
    setTimeout(()=>{
        this.updateCart();
        this.isActiveCoupon();
        if(Auth.isAuthenticated){
            this.getUserData();
        }else{
            if(Service.storeId === '3'){
                if(localStorage.getItem('pp-dTown')){
                    this.setState({dTown: localStorage.getItem('pp-dTown')})
                }
                if(localStorage.getItem('pp-dCity')){
                    this.setState({dCity: localStorage.getItem('pp-dCity')})
                }
                if(localStorage.getItem('pp-dZipCode')){
                    this.setState({dZipCode: localStorage.getItem('pp-dZipCode')})
                }
                if(localStorage.getItem('pp-dTownLabel')){
                    this.setState({dTownLabel: localStorage.getItem('pp-dTownLabel')})
                }
                if(localStorage.getItem('pp-rTownId')){
                    this.setState({rTownId: localStorage.getItem('pp-rTownId')})
                }
                if(localStorage.getItem('pp-address')){
                    this.setState({address: localStorage.getItem('pp-address')})
                }
                if(localStorage.getItem('pp-country')){
                    this.setState({country: localStorage.getItem('pp-country')})
                }
                if(localStorage.getItem('pp-email')){
                    this.setState({email: localStorage.getItem('pp-email')})
                }
                if(localStorage.getItem('pp-name')){
                    this.setState({name: localStorage.getItem('pp-name')})
                }
                if(localStorage.getItem('pp-telephone')){
                    this.setState({telephone: localStorage.getItem('pp-telephone'), telephoneDisplay: this.formatTelephone(localStorage.getItem('pp-telephone'))})
                }
                if(localStorage.getItem('pp-dStreet')){
                    this.setState({dStreet: localStorage.getItem('pp-dStreet')})
                }
                if(localStorage.getItem('pp-dStreetNumber')){
                    this.setState({dStreetNumber: localStorage.getItem('pp-dStreetNumber')})
                }
            }

            if(Service.storeId === '1'){
                if(localStorage.getItem('hk-dTown')){
                    this.setState({dTown: localStorage.getItem('hk-dTown')})
                }
                if(localStorage.getItem('hk-dCity')){
                    this.setState({dCity: localStorage.getItem('hk-dCity')})
                }
                if(localStorage.getItem('hk-dZipCode')){
                    this.setState({dZipCode: localStorage.getItem('hk-dZipCode')})
                }
                if(localStorage.getItem('hk-dTownLabel')){
                    this.setState({dTownLabel: localStorage.getItem('hk-dTownLabel')})
                }
                if(localStorage.getItem('hk-rTownId')){
                    this.setState({rTownId: localStorage.getItem('hk-rTownId')})
                }
                if(localStorage.getItem('hk-address')){
                    this.setState({address: localStorage.getItem('hk-address')})
                }
                if(localStorage.getItem('hk-country')){
                    this.setState({country: localStorage.getItem('hk-country')})
                }
                if(localStorage.getItem('hk-email')){
                    this.setState({email: localStorage.getItem('hk-email')})
                }
                if(localStorage.getItem('hk-name')){
                    this.setState({name: localStorage.getItem('hk-name')})
                }
                if(localStorage.getItem('hk-telephone')){
                    this.setState({telephone: localStorage.getItem('hk-telephone'), telephoneDisplay: this.formatTelephone(localStorage.getItem('hk-telephone'))})
                }
                if(localStorage.getItem('hk-dStreet')){
                    this.setState({dStreet: localStorage.getItem('hk-dStreet')})
                }
                if(localStorage.getItem('hk-dStreetNumber')){
                    this.setState({dStreetNumber: localStorage.getItem('hk-dStreetNumber')})
                }
            }

            if(Service.storeId === '2'){
                if(localStorage.getItem('st-dTown')){
                    this.setState({dTown: localStorage.getItem('st-dTown')})
                }
                if(localStorage.getItem('st-dCity')){
                    this.setState({dCity: localStorage.getItem('st-dCity')})
                }
                if(localStorage.getItem('st-dZipCode')){
                    this.setState({dZipCode: localStorage.getItem('st-dZipCode')})
                }
                if(localStorage.getItem('st-dTownLabel')){
                    this.setState({dTownLabel: localStorage.getItem('st-dTownLabel')})
                }
                if(localStorage.getItem('st-rTownId')){
                    this.setState({rTownId: localStorage.getItem('st-rTownId')})
                }
                if(localStorage.getItem('st-address')){
                    this.setState({address: localStorage.getItem('st-address')})
                }
                if(localStorage.getItem('st-country')){
                    this.setState({country: localStorage.getItem('st-country')})
                }
                if(localStorage.getItem('st-email')){
                    this.setState({email: localStorage.getItem('st-email')})
                }
                if(localStorage.getItem('st-name')){
                    this.setState({name: localStorage.getItem('st-name')})
                }
                if(localStorage.getItem('st-telephone')){
                    this.setState({telephone: localStorage.getItem('st-telephone'), telephoneDisplay: this.formatTelephone(localStorage.getItem('hk-telephone'))})
                }
                if(localStorage.getItem('st-dStreet')){
                    this.setState({dStreet: localStorage.getItem('st-dStreet')})
                }
                if(localStorage.getItem('st-dStreetNumber')){
                    this.setState({dStreetNumber: localStorage.getItem('st-dStreetNumber')})
                }
            }
        }

        if(
            Auth.email === 'dvojno@gmail.com' || 
            Auth.email === 'admin@publikpraktikum.com' ||
            Auth.email === 'admin.ivan@publikpraktikum.com' ||
            Auth.email === 'admin.milica@publikpraktikum.com' ||
            Auth.email === 'admin.zorana@publikpraktikum.com' ||
            Auth.email === 'admin.olja@publikpraktikum.com' ||
            Auth.email === 'admin.jaca@publikpraktikum.com' ||
            Auth.email === 'admin.milos@publikpraktikum.com' ||
            Auth.email === 'admin.bojana@publikpraktikum.com' ||
            Auth.email === 'admin.violeta@publikpraktikum.com' ||
            Auth.email === 'admin.katarina@publikpraktikum.com' ||
            Auth.email === 'admin.aleksandra@publikpraktikum.com' ||
            Auth.email === 'support1@publikpraktikum.rs' ||
            Auth.email === 'admin.marija@publikpraktikum.com'
        ){
            this.setState({admin: true});
        }
    }, 500);
    // setTimeout(()=>{this.getUserData()}, 500);
}

fbPixelPurchase=(orderId)=>{
    let products = [];
    let gtagProducts = [];
    console.log('cart', Cart);
    console.log('products', this.state.products);

    // Cart.cartArray.map(item => {
    //     products.push({id: item.id, quantity: item.quantity});
    //     gtagProducts.push({id: item.id, name: item.product.title, quantity: item.quantity, price: item.product.price});
    // })
    this.state.products.map(item => {
        products.push({id: item.id, quantity: item.quantity});
        gtagProducts.push({id: item.id, name: item.title, quantity: item.quantity, price: item.unit_price});
    })
    window.fbq('track', 'Purchase',
        {
            value: parseFloat(this.state.total),
            currency: 'RSD',
            contents: products,
            content_type: 'product'
        }
    );

    window.gtag('event', 'purchase', {
        "currency": "RSD",
        "transaction_id": orderId,
        "value": parseFloat(this.state.total),
        "items": gtagProducts
    });

    window.gtag2('event', 'conversion', {
        "currency": "RSD",
        "transaction_id": orderId,
        "value": parseFloat(this.state.total)
    });
}

fbPixelInitPurchase(){
    let products = [];
    let numItems = 0;
    let contentIds = []
    Cart.cartArray.map(item => {
        products.push({id: item.id, quantity: item.quantity});
        contentIds.push(item.id);
        numItems += item.quantity;
    })
    window.fbq('track', 'InitiateCheckout',
        {
            value: parseFloat(this.state.total),
            currency: 'RSD',
            content_ids: contentIds,
            contents: products,
            num_items: numItems,
            content_type: 'product'
        }
    );
}

// fbq('track', 'InitiateCheckout',
//   {
//     content_ids: ['partner_event_id'],
//     eventref: 'fb_oea' // or set to empty string
//   }
// );

updateShippingData=(data)=>{
    this.setState({shippingData: data},()=>{
    });
}

getUserData=()=>{
    Service.get('/users/getInfo', {},(res)=>{
        if(res.success){
            if(res.data.r_town_id){
                this.setState({
                    dTown: res.data.city,
                    dCity: res.data.municipality,
                    dZipCode: res.data.zip_code,
                    dTownLabel: res.data.city+" "+res.data.municipality+" "+res.data.zip_code,
                    rTownId: res.data.r_town_id,
                })
            }else{
                this.setState({
                    dTownLabel:""
                })
            }
            this.setState({
                address: res.data.address,
                country: 'Srbija',
                email: res.data.email,
                name: res.data.name,
                telephone: res.data.phone,
                telephoneDisplay: this.formatTelephone(res.data.phone),
                dStreet: res.data.street,
                dStreetNumber: res.data.street_number
            })
        }
    })
}

setStreet=()=>{
    let street = this.state.address.substring(0, this.state.address.lastIndexOf(" ") + 1);
    let sv = street.replace(/\s+$/, '');
    let streetNo = this.state.address.substring(this.state.address.lastIndexOf(" ") + 1, this.state.address.length);
    this.setState({dStreet: sv})
    this.setState({dStreetNumber: streetNo})
}

// getCartProductsQuantities(cartItems){
//         let r = [];
//         for(var p in cartItems){
//             r.push({id: cartItems[p].id, quantity: cartItems[p].quantity});
//         }
//         return r;
// }

updateCart=()=>{
    Cart.updateCart((cart) => {
        this.setState({total: cart.total, 
            subtotal: cart.subtotal,
            shippingPrice: cart.shippingPrice,
            appliedCoupons: cart.coupons,
            discount: cart.discount,
            products: cart.products
            //cartItems: result.data.cartProudcts
            },()=>{
                //console.log('ap',this.state.appliedCoupons)
                this.fbPixelInitPurchase();
                //this.props.updateCartDisplay();
                //duix.set('cartItems', 1);
            });
    });
}
updateCartOld=()=>{
    //Cart.clearCart();
    let ce = Cart.getCartElements();
    this.setState({cartItems: ce},()=>{
        console.log(this.state.coupon)
        let cart = Cart.updateCart();
        this.setState({total: cart.total, 
                subtotal: cart.subtotal,
                shippingPrice: cart.shippingPrice,
                appliedCoupons: cart.coupons,
                discount: cart.discount,
                products: cart.products
                //cartItems: result.data.cartProudcts
                },()=>{
                    //console.log('ap',this.state.appliedCoupons)
                    this.fbPixelInitPurchase();
                    //this.props.updateCartDisplay();
                    //duix.set('cartItems', 1);
                });

        // Service.get('/orders/getCart',{products: this.getCartProductsQuantities(this.state.cartItems), coupons: this.state.coupons},(res)=>{
            
        //     if(res.data.errors){
        //         console.log(res.data.errors);
        //     }
            
        //     this.setState({total: res.data.total, 
        //         subtotal: res.data.subtotal,
        //         shippingPrice: res.data.shippingPrice,
        //         appliedCoupons: res.data.coupons,
        //         discount: res.data.discount,
        //         products: res.data.products
        //         //cartItems: result.data.cartProudcts
        //         },()=>{
        //             //console.log('ap',this.state.appliedCoupons)
        //             this.fbPixelInitPurchase();
        //             //this.props.updateCartDisplay();
        //             //duix.set('cartItems', 1);
        //         });
        // });
    });

    
    
}

prepareOrder = () => {

    let checkedProducts = true;

    if(!Cart.cart.cartProducts > 0){
        
        this.setState({productsError: 'block'});
        checkedProducts = false;
    }else{
        this.setState({productsError: 'none'});
    }
    
    let checked = true;
    if(!this.state.name){
        this.setState({nameError: 'block'});
        checked = false;
    }else{
        this.setState({nameError: 'none'});
    }

    if(!this.state.dStreet){
        this.setState({addressError: 'block'});
        checked = false;
    }else{
        this.setState({addressError: 'none'});
    }

    if(!this.state.dStreetNumber){
        this.setState({numberError: 'block'});
        checked = false;
    }else{
        this.setState({numberError: 'none'});
    }

    if(!this.state.dTown){
        this.setState({cityError: 'block'});
        checked = false;
    }else{
        this.setState({cityError: 'none'});
    }

    if(!this.state.dZipCode){
        this.setState({zipCodeError: 'block'});
        checked = false;
    }else{
        this.setState({zipCodeError: 'none'});
    }

    if(!this.state.email || !validator.validate(this.state.email.trim()) ){
        this.setState({emailError: 'block'});
        checked = false;
    }else{
        this.setState({emailError: 'none'});
    }

    if(!this.state.telephone || this.state.telephone.length < 8){
        this.setState({telephoneError: 'block', telephoneFormatError: 'none'});
        checked = false;
    }else{
        this.setState({telephoneError: 'none', telephoneFormatError: 'block'});
    }

    if(!this.state.paymentMethod){
        this.setState({paymentMethodError: 'block'});
        checked = false;
    }else{
        this.setState({paymentMethodError: 'none'});
    }

    // Shipping data alert
    if(this.state.hasShippingAddress){
        if(!this.state.shippingData.name){
            this.setState({dNameError: 'block'});
            checked = false;
        }else{
            this.setState({dNameError: 'none'});
        }
        if(!this.state.shippingData.dShippingStreet){
            this.setState({dStreetError: 'block'});
            checked = false;
        }else{
            this.setState({dStreetError: 'none'});
        }
        if(!this.state.shippingData.dShippingTown){
            this.setState({dCityError: 'block'});
            checked = false;
        }else{
            this.setState({dCityError: 'none'});
        }
        if(!this.state.shippingData.dShippingStreetNumber){
            this.setState({dStreetNumberError: 'block'});
            checked = false;
        }else{
            this.setState({dStreetNumberError: 'none'});
        }
        if(!this.state.shippingData.telephone || this.state.shippingData.telephone.length < 8){
            this.setState({dTelephoneError: 'block'});
            checked = false;
        }else{
            this.setState({dTelephoneError: 'none'});
        }
    }
   


    if(!checked || !checkedProducts){
        this.setState({inputErrors: 'block'});
        return; 
    }else{
        this.setState({checkVisible: true, inputErrors: 'none'},()=>{
            if(!Auth.isAuthenticated){
                if(Service.storeId === '3'){
                    localStorage.setItem('pp-dTown', this.state.dTown);
                    localStorage.setItem('pp-dCity', this.state.dCity);
                    localStorage.setItem('pp-dZipCode', this.state.dZipCode);
                    localStorage.setItem('pp-dTownLabel', this.state.dTownLabel);
                    localStorage.setItem('pp-rTownId', this.state.rTownId);
                    localStorage.setItem('pp-address', this.state.address);
                    localStorage.setItem('pp-country', this.state.country);
                    localStorage.setItem('pp-email', this.state.email);
                    localStorage.setItem('pp-name', this.state.name);
                    localStorage.setItem('pp-telephone', this.state.telephone);
                    localStorage.setItem('pp-dStreet', this.state.dStreet);
                    localStorage.setItem('pp-dStreetNumber', this.state.dStreetNumber);
                }
               
                if(Service.storeId === '1'){
                    localStorage.setItem('hk-dTown', this.state.dTown);
                    localStorage.setItem('hk-dCity', this.state.dCity);
                    localStorage.setItem('hk-dZipCode', this.state.dZipCode);
                    localStorage.setItem('hk-dTownLabel', this.state.dTownLabel);
                    localStorage.setItem('hk-rTownId', this.state.rTownId);
                    localStorage.setItem('hk-address', this.state.address);
                    localStorage.setItem('hk-country', this.state.country);
                    localStorage.setItem('hk-email', this.state.email);
                    localStorage.setItem('hk-name', this.state.name);
                    localStorage.setItem('hk-telephone', this.state.telephone);
                    localStorage.setItem('hk-dStreet', this.state.dStreet);
                    localStorage.setItem('hk-dStreetNumber', this.state.dStreetNumber);
                }

                if(Service.storeId === '2'){
                    localStorage.setItem('st-dTown', this.state.dTown);
                    localStorage.setItem('st-dCity', this.state.dCity);
                    localStorage.setItem('st-dZipCode', this.state.dZipCode);
                    localStorage.setItem('st-dTownLabel', this.state.dTownLabel);
                    localStorage.setItem('st-rTownId', this.state.rTownId);
                    localStorage.setItem('st-address', this.state.address);
                    localStorage.setItem('st-country', this.state.country);
                    localStorage.setItem('st-email', this.state.email);
                    localStorage.setItem('st-name', this.state.name);
                    localStorage.setItem('st-telephone', this.state.telephone);
                    localStorage.setItem('st-dStreet', this.state.dStreet);
                    localStorage.setItem('st-dStreetNumber', this.state.dStreetNumber);
                }
            }
        });
    }

    if(!checkedProducts){
        return;
    }else{
        this.setState({checkVisible: true});
    }
}

closeCheck = () => {
    this.setState({checkVisible: false});
}

createOrder = () => {
    this.closeCheck();
    this.setState({loaderVisible: true});

    let products = [];
    console.log(Cart);
    Cart.cartArray.map((item)=>{
        products.push({id: item.id, quantity: item.quantity});
        return 1;
    });

    

    Service.createOrder({
            Auth, 
            products: Cart.getCartProductsQuantities(),
            coupons: Cart.coupons,
            name: this.state.name,
            billingAddress: this.state.dStreet + " " + this.state.dStreetNumber,
            street: this.state.dStreet,
            streetNumber: this.state.dStreetNumber,
            // shippingAddress: this.state.address,
            city: this.state.dTown,
            municipality: this.state.dCity,
            phone: this.state.telephone,
            email: this.state.email ? this.state.email.replace(/\s/g,'') : this.state.email,
            zipCode: this.state.dZipCode,
            country: this.state.country,
            paymentMethod: this.state.paymentMethod,
            source: this.state.source,
            note: this.state.note,
            hasShippingAddress: this.state.hasShippingAddress,
            shippingName: this.state.shippingData.name,
            shippingAddress: this.state.shippingData.dShippingStreet+ " " + this.state.shippingData.dShippingStreetNumber,
            shippingStreet: this.state.shippingData.dShippingStreet,
            shippingStreetNumber: this.state.shippingData.dShippingStreetNumber,
            shippingCity: this.state.shippingData.dShippingTown,
            shippingMunicipality: this.state.shippingData.dShippingCity,
            shippingPhone: this.state.shippingData.telephone,
            shippingZipCode: this.state.shippingData.dShippingZipCode,
            shippingCountry: this.state.shippingData.country,
            shippingEmail: this.state.shippingData.email ? this.state.shippingData.email.replace(/\s/g,'') : this.state.shippingData.email,
            rTownId: this.state.rTownId,
            shippingRTownId: this.state.shippingData.shippingTownId
        },(response)=>{

            if(response.success){

                this.fbPixelPurchase(response.data.form.orderId);

                

                this.setState({
                    hash: response.data.form.hash,
                    rnd: response.data.form.rnd,
                    orderId: response.data.form.orderId,
                    amount: response.data.form.amount,
                    okUrl: response.data.form.urlSuccess,
                    failUrl: response.data.form.urlFail,
                    clientId: response.data.form.clientId,
                    shopUrl: response.data.form.shopUrl,
                    hashAlgorithm: response.data.form.hashAlgorithm,
                    transactionType: response.data.form.transactionType,
                    storeType: response.data.form.storeType,
                    currency: response.data.form.currency,
                    lang: response.data.form.lang,
                    encoding: response.data.form.encoding,
                    paymentMethod: response.data.form.paymentMethod,  //@todo: uzeti sve podatke iz backenda
                    orderHash: response.order_hash
                },()=>{

                    //if payment method is credit card submit form
                    if(this.state.paymentMethod === 'cc'){
                        var form = this.formRef.current;
                        // show loader
                        this.setState({loaderVisible: true});
                        form.submit();
                    }else{
                        this.props.history.push('/success/pp/'+ response.order_hash);
                    }
                });
            }else{
                //alert(response.error);
                let message = '<p>';
                response.products.map(item => {
                    if(item.id){
                        Cart.removeFromCart(parseInt(item.id));
                        this.updateCart();
                    }
                    message += `"${item.title}"<br />`;
                });
                message += '</p>';
                duix.set('dialog', `<h3>Proizvodi nedostupni</h3><p>Na žalost, sledeći proizvodi više nisu dostupni</p>${message}<p>Proizvodi su izbačeni iz korpe. Molimo vas da ponovo potvrdite porudžbinu</p>`);
                this.setState({loaderVisible: false});
            }
    });
    
}

handleInput=(event)=>{
    this.setState({[event.target.id]: event.target.value},
        ()=>{}
    );
    
}

handleInputData=(event)=>{
    //setPaymentMethod
    console.log(event.target.value)
    Cart.setPaymentMethod(event.target.value);
    this.updateCart();
    this.setState({[event.target.getAttribute('data-id')]: event.target.value},
        ()=>{
            
        }
    );
    
}

handleNoteChange = (event) => {
    let alphaNumNote = event.target.value.replace(/[^0-9a-zA-ZŠĐŽĆČŽšđčćž.,:\s]/g,'');
    this.setState({note: alphaNumNote});
}

    removeProduct=(productId)=>{
        if(window.confirm('Da li ste sigurni da želite da izbacite proizvod iz korpe?')){
            Cart.removeFromCart(productId);
            this.updateCart();
        }
        
    }

    changeQuantity = (productId, value, product) => {
        if(value === 'add'){
            Cart.addToCart(productId, product);
        }

        if(value === 'remove'){
            Cart.removeOneFromCart(productId);
        }

        this.updateCart();

    }

handlePhoneNumber=(event)=>{
    // if(event.target.value === "0"){
    //     this.setState({telephone: ''},()=>{
            
    //     })
    // }else 
    if(event.target.value.length < 15){
        let p = event.target.value.replace(/[^0-9.]/g, '');
        if(p.substring(0,3) == '381'){
            p = p.substring(3);
        }

        this.setState({telephone: p, telephoneDisplay: this.formatTelephone(p)},()=>{
        })
    }
}

formatTelephone(phone){
    return phone;
    if(phone){
        let p = phone.toString();
        let rest = p.substring(6);
        let second = p.substring(2,6);
        let first = p.substring(0,2);

        if(p.length < 3){
            return first;
        }else if(p.length < 7){
            return '(' + first + ') ' + second;
        }else{
            return '(' + first + ') ' + second + ' ' + rest;
        }
    }
     
}
    
handleShippingAddress = event => {
    this.setState({ hasShippingAddress: event.target.checked }, ()=>{
        this.executeScroll();
    })
    
}

handleTownCheckbox = event => {
    this.setState({ townCheckbox: event.target.checked })
}

setAddress=(data)=>{
    this.setState({dStreet: data[0].value.replace(/[^0-9a-zA-Z.ŠĐŽĆČŽšđčćž\s]/g,'')},()=>{
    })
}

setStreetNumber=(event)=>{

    this.setState({dStreetNumber: parseStreetNumber(event.target.value)})
}

correctEmptyStreetNumber=()=>{
    if(this.state.dStreetNumber == 'b' || this.state.dStreetNumber == 'B' || !this.state.dStreetNumber){
        this.setState({dStreetNumber: 'bb'})
    }
}

setTown=(data)=>{
    this.setState({
        dTown: data[0].town_name,
        dCity: data[0].city_name,
        dZipCode: data[0].ptt_no,
        dTownLabel: data[0].label,
        rTownId: data[0].town_id
    })
}

setName=(event)=>{
    this.setState({name: event.target.value.replace(/[^A-Za-zŠĐŽĆČŽšđčćž\s!?]/g,'')})
}

clearCart=()=>{
    Cart.clearCart();
    this.updateCart();
}

handleCoupon=(event)=>{
    this.setState({coupon: event.target.value, couponError: ''});
}

applyCoupon=()=>{
    Service.get('/orders/applycoupon', {coupon: this.state.coupon},(res)=>{
        if(res.success){
            if(!Cart.coupons.includes(res.coupon)){
                
                Cart.addCoupon(res.coupon, ()=>{
                    this.setState(prevState => ({
                        coupons: [...prevState.coupons, res.coupon]
                    }), ()=>{
                        this.setState({couponError: '', coupon: ''});
                        this.updateCart();
                    })
                });
            }else{
                //alert('Ovaj kupon je već iskorišćen');
                this.setState({couponError: 'Ovaj kupon je već iskorišćen u korpi', coupon: ''});
            }
        }else{
            if(res.message){
                this.setState({couponError: res.message, coupon: ''});
            }
        }
    })
}

removeCoupon=(event)=>{
    Cart.removeCoupon(event.target.getAttribute('data-coupon'), ()=>{
        this.updateCart();
    });
}

isActiveCoupon=()=>{
    Service.get('/orders/isActiveCoupon', {},(res)=>{
        if(res.success){
            this.setState({isActiveCoupon: res.active_coupons})
        }
    })
}

handleCouponPress=(event)=>{
    if (event.charCode === 13) {
        console.log('test');
        this.setState({coupon: event.target.value},()=>{
            this.applyCoupon();
        })
    }
}

render() {
return (
    <div>
        <Helmet>
            <title>{Service.websiteTitleName} | Korpa</title>
        </Helmet>
        <h2>Korpa</h2>
        <button className="clear-cart-button stela-shape" onClick={()=>{this.clearCart()}}>Isprazni korpu</button>
        <div className="cart-product cart-product-head stela-shape">
                <div className="image cart-items">1. Sadržaj Korpe</div>
                <div className="title">Naslov</div>
                <div className="quantity">Količina</div>
                <div className="price">Cena</div>
                <div className="trash"></div>
            </div>
        
        {   this.state.products.length > 0 &&
            <div>
            {this.state.products.map(function(item, index){
            return(
                <div className="cart-product" key={index+'cp'}>
                    <Link to={`/proizvod/${item.id}/${item.slug}`} ><div className="image"><img src={Service.images(item.slug + '.jpg')} alt={item.slug} /></div></Link>
                    {item.discounts && 
                        <Discounts discounts={item.discounts}/>
                    }
                    <Link to={`/proizvod/${item.id}/${item.slug}`} ><div className="title">{item.title}</div></Link>
                    <div className="quantity">
                        <button onClick={()=>{this.changeQuantity(item.id, 'remove', item)}}>-</button>
                        <span>{item.quantity}</span>
                        <button onClick={()=>{this.changeQuantity(item.id, 'add', item)}}>+</button>
                    </div>
                    <div className="price">
                        {item.price != item.full_price &&
                            <div className="cart-full-price-cart-item" >{Service.price(item.full_price * item.quantity)}</div>
                        }
                        <div className="cart-item-price">{Service.price(item.total)}</div>
                        {item.appliedDiscounts && 
                            // <div className="cart-discount-sticker">{item.discountType}</div>
                            <div className="cart-discount-stickers">
                                {item.appliedDiscounts.map((discount, index)=>{
                                    return(
                                    <Link to={'/proizvodi/kategorije/ds_'+discount.id+'/'+parseDiscountName(discount.description)} >
                                        <img src={discount.image} />
                                    </Link>
                                    )
                                })}
                            </div>
                            
                        }
                        
                    </div>
                    <div className="trash"><button onClick={()=>{this.removeProduct(item.id)}}><i className="fas fa-trash"></i></button></div>
                </div>
            )
            }, this)}
                <div className="cart-total">
                    <p>ZBIR: {Service.price(this.state.subtotal)}</p>
                    {this.state.appliedCoupons.length > 0 &&
                        <div className="cart-coupons">
                            {this.state.appliedCoupons.map(function(item, index){
                                return(
                                <p key={index+'cpn'}>Kupon: <b>"{item.name}"</b> {item.description}: -{Service.price(item.discountedAmount)}<i className="fas fa-trash" data-coupon={item.name} onClick={this.removeCoupon}></i></p>
                                )},this)}
                        </div>
                    }
                    {this.state.discount > 0 &&
                        <p className="cart-saved">UŠTEDA: {Service.price(this.state.discount)}</p>
                    }   
                    <p>ISPORUKA: {Service.price(this.state.shippingPrice)}</p>
                    <h3 className="cart-total-price"><strong>UKUPNO ZA PLAĆANJE: {Service.price(this.state.total)}</strong></h3>
                    <h5>Sve cene su sa uračunatim PDV-om.</h5>
                    {parseFloat(this.state.shippingPrice) > 0 &&
                        <h3 className="cart-free-shipping">Besplatna dostava za porudžbine preko 3000 dinara.</h3>
                    }
                </div>

                {this.state.isActiveCoupon &&
                    <div className="coupon-box">
                        <h3>Imate kupon?</h3>
                        <input type="text" id='coupon' value={this.state.coupon} onChange={this.handleCoupon} onKeyPress={this.handleCouponPress}/>
                        <button onClick={this.applyCoupon} >Potvrdi</button>
                        <p className="error" style={{display: 'block', position:'relative', marginTop: '1em'}}>{this.state.couponError}</p>
                    </div>
                }
            </div>
        }
        
        { this.state.products.length <= 0 &&
            <p>Vasa korpa je prazna</p>  
        }

        <div className="cart-product cart-product-head stela-shape">
            <div className="head-title">2. Podaci za isporuku</div>

        </div>
        <div className="cart-inputs">
            <h3>Ime i prezime</h3>
            <input type="text" id='name' value={this.state.name} onChange={this.setName} />
            <p className="error" style={{display: this.state.nameError}}>* morate da unesete ime i prezime.</p>
            <div className="cart-street-box">
                <h3>Adresa</h3>
                {/* <Selector route='/orders/getStreets' filters={{competition: this.state.competition, brands: this.state.selectedBrands, subBrands: this.state.selectedSubBrands}} placeholder="Adresa..."/> */}
                {/* <input type="text" id='address' value={this.state.address} onChange={this.handleInput} /> */}
                <Selector onChange={this.setAddress} route='/orders/getStreets' inputValue={this.state.dStreet} hasSelect={this.state.dStreet ? true : false} forced={true} type="single"/>
                <p className="error" style={{display: this.state.addressError}}>* morate da unesete adresu.</p>
            </div>
            <div className="cart-streetNum-box">
                <h3>Broj</h3>
                <input type="text" id='dStreetNumber' value={this.state.dStreetNumber} onChange={this.setStreetNumber} onBlur={this.correctEmptyStreetNumber}/>
                <p className="error" style={{display: this.state.numberError}}>* morate da unesete broj.</p>
            </div>
            
            {/* <h3>Opština</h3>
            <input type="text" id='municipality' value={this.state.municipality} onChange={this.handleInput} /> */}
            {!this.state.townCheckbox &&
                <Fragment>
                    <h3>Grad</h3>
                    <Selector onChange={this.setTown} route='/orders/getTowns' filters={{street: this.state.dStreet}} inputValue={this.state.dTownLabel} hasSelect={this.state.dTownLabel ? true : false} type="single"/>
                    {/* <input type="text" id='city' value={this.state.city} onChange={this.handleInput} /> */}
                    <p className="error" style={{display: this.state.cityError}}>* morate da odaberete drugo mesto.</p>
                </Fragment>
            }

            {this.state.townCheckbox &&
                <Fragment>
                    <h3>Grad</h3>
                    <Selector onChange={this.setTown} route='/orders/getAllTowns' inputValue={this.state.dTownLabel} hasSelect={this.state.dTownLabel ? true : false} type="single"/>
                    {/* <input type="text" id='city' value={this.state.city} onChange={this.handleInput} /> */}
                    <p className="error" style={{display: this.state.cityError}}>* morate da odaberete drugo mesto.</p>
                </Fragment>
            }
            {/* <div className="town-checkbox">
                <input id='tcb' type="checkbox" style={{display: 'inline-block', verticalAlign: 'middle', width:'5%'}} value={this.state.townCheckbox} onChange={this.handleTownCheckbox} />
                <label htmlFor="tcb" style={{display: 'inline-block', verticalAlign: 'middle'}}> Izaberite drugo mesto</label>
            </div> */}
        </div>
        <div className="cart-inputs">
            {/* <h3>Poštanski broj</h3>
            <input type="text" id='zipCode' value={this.state.zipCode} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.zipCodeError}}>* morate da unesete poštanski broj.</p> */}
            <h3>Država</h3>
            {/* <input type="text" id='country' value={this.state.country} onChange={this.handleInput} /> */}
            <input type="text" id='country' value='Srbija' readOnly/>
            <h3>Broj telefona</h3>
            <div className="telephone-area-code">
                <input type="text" value="+381" readOnly/>
            </div>
            <div className="telephone-number">
                <input type="text" id='telephone' value={this.state.telephoneDisplay}  onChange={this.handlePhoneNumber} maxLength="10" />
                <p className="error" style={{display: this.state.telephoneError}}>* morate da unesete vaš broj telefona.</p>
                <p className="format-phone-info" style={{display: this.state.telephoneFormatError}}>Možete upisati samo srpski broj telefona</p>
            </div>
            <h3>Email</h3>
            {/* <input type="text" id='email' value={this.state.email} onChange={this.handleInput} readOnly={this.state.admin}/> */}
            <input type="text" id='email' value={this.state.email} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.emailError}}>* morate da unesete vaš email.</p>
            
            {this.state.admin === true &&
                <fragment>
                <p>Admin: Odaberi odakle je došla porudžbina:</p>
                    <select id='source' value={this.state.source} onChange={this.handleInput}>
                        <option value='website'>website</option>
                        <option value='instagram'>Instagram</option>
                        <option value='facebook'>Facebook</option>    
                        <option value='telephone'>Telephone</option> 
                    </select>
                </fragment>
            }
        </div>
        <div className="cart-inputs">
            <h3>Napomena</h3>
            <textarea className="order-note" placeholder="Napišite napomenu za isporuku. Maksimalan broj karaktera je 150." value={this.state.note} onChange={this.handleNoteChange} maxLength="149"></textarea>
            {/* <div>
                <h3>Nudimo brojne pogodnosti za registrovane kupce:</h3>
                <ul>
                    <li>Specijalni popusti za online kupovinu</li>
                    <li>Rođendanski popusti</li>
                    <li>Novogodišnji popusti</li>
                </ul>
                <Link to="/registracija" ><button className="add-to-cart-button" >Registrujte se!</button></Link>
            </div> */}
        </div>
        <div className="shipping-selector">
        <input id='scb' type="checkbox" style={{display: 'inline-block', verticalAlign: 'middle'}} value={this.state.hasShippingAddress} onChange={this.handleShippingAddress} />
        <label htmlFor="scb" ref={this.diffShipping} className="different-shipping-label"> <b>Adresa isporuke</b> je drugačija od adrese plaćanja? Kliknite ovde i popunite podatke vezano za adresu isporuke.</label>
        </div>
        {this.state.hasShippingAddress === true &&                                  
            <Shipping updateShippingData={this.updateShippingData}  dNameError={this.state.dNameError} dStreetError={this.state.dStreetError} dCityError={this.state.dCityError} dStreetNumberError={this.state.dStreetNumberError} dTelephoneError={this.state.dTelephoneError}/>
        }
        

        {/* PAYMENT */}
        <div className="cart-product cart-product-head payment-head stela-shape">
            <div className="head-title">3. Način isporuke i način plaćanja</div>
            {/* <div className="cart-head-half">4. Način plaćanja</div> */}
        </div>
        <div className="cart-inputs-billing">
            <input type="radio" className="cart-input-radio" name="shipping-method" value="0" defaultChecked="checked"/>
            {/* {parseFloat(this.state.shippingPrice) > 0 && */}
            <div>
                <h4>Kurirskom službom - {Service.price(this.state.shippingPrice)}</h4>
                
                <br />
                Porudžbine se vrše putem kuriske službe DailyExpress.
                <br />
                <br />
                <strong>Za porudžbine preko 3000 dinara dostava je besplatna.</strong>
            </div>
            
            {/* // {parseInt(this.state.shippingPrice) <= 0 &&
            // <div>
            //     <h4>Kurirskom službom - RSD 0,00</h4>
            //     <br />
            //     Porudžbine se vrše putem kuriske službe DailyExpress.
            // </div>
            // } */}
        </div>
        <div className="cart-inputs-billing">
            <input type="radio" className="cart-input-radio" checked={this.state.paymentMethod == 'cc'} name="billing-method" id="paymentMethod-cc" data-id="paymentMethod" value="cc" onChange={this.handleInputData}/>
                <label htmlFor="paymentMethod-cc">
                    <div>
                        <h4>Platnom karticom</h4>
                        Plaćanje karticom putem sigurnog sistema Banke Intese.
                    </div>
                </label>
            <br />
            <input type="radio" className="cart-input-radio" checked={this.state.paymentMethod == 'pp'} name="billing-method" id="paymentMethod-pp" data-id="paymentMethod" value="pp" onChange={this.handleInputData}/>
                <label htmlFor="paymentMethod-pp">
                <div>
                    <h4>Pouzećem</h4>
                    Plaćanje kuriru prilikom dostave paketa.
                </div>
                </label>
            <p className="error" style={{display: this.state.paymentMethodError}}>* morate da odaberete način plaćanja.</p>
        </div>

        



        <div className="cart-submit">
            <div style={{paddingBottom: '1em'}} >
                Isporuka: {Service.price(this.state.shippingPrice)} <br/>
                <b>Ukupno za plaćanje: {Service.price(this.state.total)} </b>
                <br/>
            </div>
            <SajamText />
            
            {/* Sve cene su iskazane sa uračunatim PDV-om - nema dodatnih troškova. <br />
            Klikom na dugme <strong>'NARUČI'</strong>, prihvatate <Link to="/pravila">uslove kupovine</Link>
            <br /> */}
            {/* { this.state.loaderVisible === false && */}
                {/* // <button onClick={this.prepareOrder} >Naruči</button> */}
                <div className="button button-order stela-shape" onClick={this.prepareOrder} >Naruči</div>
            {/* } */}
            <div>
            Sve cene su iskazane sa uračunatim PDV-om - nema dodatnih troškova. <br />
            Klikom na dugme <strong>'NARUČI'</strong>, prihvatate <Link to="/pravila">uslove kupovine</Link>.
            </div>
            {/* <Loader
                type="Oval"
                color="#01A7A5"
                height={100}
                width={100}
                timeout={100000} //3 secs
                //visible={this.state.loaderVisible}
                visible={true}
            /> */}
        </div>
        <p className="error" style={{display: this.state.inputErrors, textAlign: 'center', width: '100%', position: 'relative', fontSize: '1.2em'}}>* Niste uneli sve potrebne podatke.</p>
        <p className="error" style={{display: this.state.productsError, textAlign: 'center', width: '100%', position: 'relative', fontSize: '1.2em'}}>* Vaša korpa je prazna, molimo vas da ubacite neki proizvod u korpu.</p>
        {/* CC form */}

        <form action={Service.paymentUrl} ref={this.formRef} id="form" method='POST'>
            <input type="hidden" name="clientid" value={this.state.clientId}/>
            <input type="hidden" name="storetype" value={this.state.storeType} />
            <input type="hidden" name="hashAlgorithm" value={this.state.hashAlgorithm} />
            <input type="hidden" name="hash" value={this.state.hash} />
            <input type="hidden" name="trantype" value={this.state.transactionType} />
            <input type="hidden" name="amount" value={this.state.amount} />
            <input type="hidden" name="currency" value={this.state.currency} />
            <input type="hidden" name="oid" value={this.state.orderId} />
            <input type="hidden" name="okUrl" value={this.state.okUrl}/>
            <input type="hidden" name="failUrl" value={this.state.failUrl} />
            <input type="hidden" name="lang" value={this.state.lang} />
            <input type="hidden" name="rnd" value={this.state.rnd} />
            <input type="hidden" name="encoding" value={this.state.encoding} />
            <input type='hidden' name='shopurl' value={this.state.shopUrl} />
        </form>

        {this.state.checkVisible === true &&
        <div className="modal-container" >
            <div className="modal" style={{display: 'block'}}>
                <div className="modal-close-button" onClick={this.closeCheck}>X</div>
                <div className="modal-content">
                    <h2>Potvrdite podatke iz porudžbine</h2>
                    <h3>Artikli</h3>
                    <table className="cp-modal-table">
                        <tr>
                            <td>Naslov</td>
                            <td>Količina</td>
                            <td>Ukupno</td>
                        </tr>

                        {this.state.products.map(function(item, index){
                            let totalProductPrice = item.total;//item.quantity * item.product.price;
                            return(
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{item.quantity} KOM.</td>
                                    <td>{Service.price(totalProductPrice)}</td>
                                </tr>
                            )
                        },this)
                        }
                    </table>
                    <div style={{textAlign: "right"}}>
                        Ukupno: {Service.price(this.state.subtotal)}<br/>
                        Isporuka: {Service.price(this.state.shippingPrice)} <br/>
                        <b>Total: {Service.price(this.state.total)} </b>
                    </div>
                    <p>Način plaćanja: <b>{this.state.paymentMethod === 'cc' ? 'Platnom karticom' : 'Pouzećem'}</b></p>
                    
                    <div className="checkout-modal-info-left">
                        <h3>Adresa plaćanja</h3>
                        Ime i prezime: {this.state.name}<br/>
                        Email: {this.state.email}<br/>
                        Broj telefona: {this.state.telephone}<br/>

                        Adresa plaćanja: {this.state.dStreet + " " + this.state.dStreetNumber}<br/>
                        Grad: {this.state.dTown + " " + this.state.dZipCode}<br/>
                        Opština: {this.state.dCity}<br/>
                        Država: {this.state.country}<br/>

                    </div>
                    {this.state.hasShippingAddress ? 
                        <div className="checkout-modal-info-right">
                            <h3>Adresa isporuke</h3>
                            Ime i prezime isporuke: {this.state.shippingData.name}<br/>
                            Email: {this.state.shippingData.email}<br/>
                            Broj telefona: {this.state.shippingData.telephone}<br/>
                            Adresa isporuke: {this.state.shippingData.dShippingStreet+ " " + this.state.shippingData.dShippingStreetNumber}<br/>
                            Grad: {this.state.shippingData.dShippingTown + " " + this.state.shippingData.dShippingZipCode}<br/>
                            Opština: {this.state.shippingData.dShippingCity}<br/>
                            Država: {this.state.shippingData.country}<br/>
                        </div>
                        :
                        <div className="checkout-modal-info-right">
                            <h3>Adresa isporuke</h3>
                            Ime i prezime: {this.state.name}<br/>
                            Email: {this.state.email}<br/>
                            Broj telefona: {this.state.telephone}<br/>

                            Adresa: {this.state.dStreet + " " + this.state.dStreetNumber}<br/>
                            Grad: {this.state.dTown + " " + this.state.dZipCode}<br/>
                            Opština: {this.state.dCity}<br/>
                            Država: {this.state.country}<br/>
                        </div>
                    }
                </div>
               
                <div className="modal-ok-button" onClick={this.createOrder}>OK</div>
            </div>
            </div>
        }
        <Overlay visible={this.state.loaderVisible} text='Pravljenje porudžbine. Molimo sačekajte...'/>
        </div>
    );

    
}

}

export default CartPage;