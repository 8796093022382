import Service from '../components/Service';
import duix from 'duix';
import {NotificationManager} from 'react-notifications';


export const Cart = {

    cartArray: [],
    cartTotal: 0,
    cartShipping: 0,
    coupons: [],
    cart: {},
    paymentMethod: 'cc',

    initCart(){
        if(Service.storeId === '3'){
            if(localStorage.getItem('pp-cartItems')){
                this.cartArray = JSON.parse(localStorage.getItem('pp-cartItems'));
            }
            if(localStorage.getItem('pp-coupons')){
                this.coupons = JSON.parse(localStorage.getItem('pp-coupons'));
            }
        }

        if(Service.storeId === '1'){
            if(localStorage.getItem('hk-cartItems')){
                this.cartArray = JSON.parse(localStorage.getItem('hk-cartItems'));
            }
            if(localStorage.getItem('hk-coupons')){
                this.coupons = JSON.parse(localStorage.getItem('hk-coupons'));
            }
        }

        if(Service.storeId === '2'){
            if(localStorage.getItem('st-cartItems')){
                this.cartArray = JSON.parse(localStorage.getItem('st-cartItems'));
            }
            if(localStorage.getItem('st-coupons')){
                this.coupons = JSON.parse(localStorage.getItem('st-coupons'));
            }
        }
        this.updateCart();
    },

    setPaymentMethod(paymentMethod){
        this.paymentMethod = paymentMethod;
    },

    addToCart(id, product){

        for(let p in this.cartArray){
            //ukoliko se id poklapa sa isbn koji je prosledjen
            if(this.cartArray[p].id === id){
                this.cartArray[p].quantity ++;
                this.updateCart();
                if(Service.storeId === '3'){
                    localStorage.setItem('pp-cartItems', JSON.stringify(this.cartArray));
                }
                if(Service.storeId === '1'){
                    localStorage.setItem('hk-cartItems', JSON.stringify(this.cartArray));
                }
                if(Service.storeId === '2'){
                    localStorage.setItem('st-cartItems', JSON.stringify(this.cartArray));
                }
                //duix.set('modal', 'Proizvod je dodat u korpu.');
                //duix.set('cartItems', this.cartArray);

                return;
            }else{
                
            }
           
        }
        //dodavanje u niz

        this.cartArray.push({id: id, quantity:1});
        this.updateCart();
        if(Service.storeId === '3'){
            localStorage.setItem('pp-cartItems', JSON.stringify(this.cartArray));
        }

        if(Service.storeId === '1'){
            localStorage.setItem('hk-cartItems', JSON.stringify(this.cartArray));
        }

        if(Service.storeId === '2'){
            localStorage.setItem('st-cartItems', JSON.stringify(this.cartArray));
        }
        // duix.set('modal', 'Proizvod je dodat u korpu.');
        NotificationManager.success('Proizvod je dodat u korpu.', 'Korpa:')
        //duix.set('cartItems', this.cartArray);


        window.fbq('track', 'AddToCart', {
            content_name: product.title, 
            content_category: 'Knjige',
            content_ids: [product.id],
            content_type: 'product',
            value: parseInt(product.price),
            currency: "RSD" 
        });
        window.gtag('event', 'add_to_cart', {
        "items": [
            {
            "id": product.id,
            "name": product.title,
            "list_name": "Knjige",
            "brand": "Publik praktikum",
            "category": "n/a",
            "quantity": 1,
            "price": parseInt(product.price)
            }
        ]
        });


    },


    clearCart(){
        if(Service.storeId === '3'){
            localStorage.removeItem('pp-cartItems')
        }
        if(Service.storeId === '1'){
            localStorage.removeItem('hk-cartItems')
        }
        if(Service.storeId === '2'){
            localStorage.removeItem('st-cartItems')
        }
        this.cartArray = [];
        this.coupons = [];
        this.updateCart();
        //duix.set('cartItems', this.cartArray);
    },

    getTotal(){
        return this.cartTotal;
    },

    getCartShipping(){
        return this.cartShipping;
    },

    getCart(){
        this.updateCart();
    },
    getCartElements(){

        return this.cartArray;
    },
    getCartProductsQuantities(){
        let r = [];
        for(var p in this.cartArray){
            r.push({id: this.cartArray[p].id, quantity: this.cartArray[p].quantity});
        }
        return r;
    },
    getCartProductsNumber(){
        return this.cartArray.length;
    },
    removeIfLastCopy(product){
        this.cartArray.splice(this.cartArray.indexOf(product),1);
        this.updateCart();
    },
    removeFromCart(id){

        for(let p in this.cartArray){
            if(this.cartArray[p].id === id){
                this.cartArray.splice(p,1);
            }
        }
        this.updateCart();
        if(Service.storeId === '3'){
            localStorage.setItem('pp-cartItems', JSON.stringify(this.cartArray));
        }
        if(Service.storeId === '1'){
            localStorage.setItem('hk-cartItems', JSON.stringify(this.cartArray));
        }
        if(Service.storeId === '2'){
            localStorage.setItem('st-cartItems', JSON.stringify(this.cartArray));
        }
    },

    removeOneFromCart(productId){
        for(let p in this.cartArray){
            if(this.cartArray[p].id === productId){
                //isto kao i u funkciji addToCart samo sto smanjuje
                this.cartArray[p].quantity --;
                if(this.cartArray[p].quantity < 1){
                    //ukoliko value dodje na 0, brise se ceo element na p poziciji
                    this.removeIfLastCopy(this.cartArray[p]);
                }
                if(Service.storeId === '3'){
                    localStorage.setItem('pp-cartItems', JSON.stringify(this.cartArray));
                }
                if(Service.storeId === '1'){
                    localStorage.setItem('hk-cartItems', JSON.stringify(this.cartArray));
                }
                if(Service.storeId === '2'){
                    localStorage.setItem('st-cartItems', JSON.stringify(this.cartArray));
                }
                this.updateCart();

                return;
            }
           
        }

    },

    addCoupon(coupon, cb=null){
        
        this.coupons.push(coupon);
        if(Service.storeId === '3'){
            localStorage.setItem('pp-coupons', JSON.stringify(this.coupons));
        }
        if(Service.storeId === '1'){
            localStorage.setItem('hk-coupons', JSON.stringify(this.coupons));
        }
        if(Service.storeId === '2'){
            localStorage.setItem('st-coupons', JSON.stringify(this.coupons));
        }
        if(cb){
            cb();
        }
    },

    removeCoupon(coupon, cb=null){
        this.coupons.map((item, index)=>{
            if(item.toLowerCase() == coupon.toLowerCase()){
                this.coupons.splice(index, 1);
            }
        })
        if(Service.storeId === '3'){
            localStorage.removeItem('pp-coupons', JSON.stringify(this.coupons));
        }
        if(Service.storeId === '1'){
            localStorage.removeItem('hk-coupons', JSON.stringify(this.coupons));
        }
        if(Service.storeId === '2'){
            localStorage.removeItem('st-coupons', JSON.stringify(this.coupons));
        }
        if(cb){
            cb();
        }
    },

    updateCart(cb=null){

        Service.get('/orders/getCart',{products: this.getCartProductsQuantities(), coupons: this.coupons, paymentMethod: this.paymentMethod},(res)=>{
            
            this.cart = res.data;
            duix.set('cartItems', this.cart);
            if(cb){
                cb(this.cart);
            }
        });

    }
    
 
}