import React, { Component, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Service from '../components/Service';

class Banners extends Component {
    constructor(){
        super();
        this.state={
            banners: [] ,
            storeId: '',
            links: [],
            autoplay: false
        }

    }

   

    linkTo = (index) => {

        if(this.state.links[index]){
            
            window.location.href = this.state.links[index];
        }
        
    }
    
  
    



componentDidMount(){
    if(Service.storeId === '3'){
        this.setState({storeId: 3},()=>{
            this.getImages();
        })
    }
    if(Service.storeId === '1'){
        this.setState({storeId: 1},()=>{
            this.getImages();
        })
    }
    if(Service.storeId === '2'){
        this.setState({storeId: 2},()=>{
            this.getImages();
        })
    }
}

getImages=()=>{
    Service.get("/news/getbanners",{storeId: this.state.storeId}, (res) => {
        this.setState({ banners: res.data.banners },()=>{
           let l = [];
            this.state.banners.map(function(item){
                l.push(item.link);
            })
            
            this.setState({links: l, autoplay: true });
        })

    });
    
    


}

    render() {
        return (     
    <Carousel showThumbs={false} interval={6000} infiniteLoop={true} autoPlay={this.state.autoplay} onClickItem={(e)=>{this.linkTo(e)}}>
            
               
        {this.state.banners.map(function (item, index) {
                return (
            <div  key={index}> 

            <div>
             <img src={Service.images('banners/'+item.banner_name)} /> 
           
            </div>
                    

            </div>    
            
         )
        }, this)
        }
    
                
            
    </Carousel>
    
            
        );
    }
};

export default Banners;