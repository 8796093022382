import React, { useState, useEffect } from "react";
import Service from '../components/Service';

function CentralBanner() {
    const [banner, setBanner] = useState(null);

    useEffect(() => {
        Service.get('/news/getCentralBanner',{},(res)=>{
            setBanner(res.banner);
        })
    }, [])

    return(
        <div className="home-static-banner">
            {banner &&
                <a href={banner.link}>
                    <img src={banner.url} />
                </a>
            }
        </div>
    )

}

export default CentralBanner;