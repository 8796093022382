import React, { Component, Fragment } from 'react';
const warningStyle = {
    color: 'darkred',
    border: '1px solid darkred',
    padding: '.5em',
    textAlign: 'center',
    background: 'white'
  };
class Shipping extends Component{

    
      render(){
        return(
            <div>
            {/* <p style={warningStyle} className="sajam-text" ><strong>MOLIMO ZA STRPLJENJE, ZBOG VELIKOG BROJA PORUDŽBINA, ROK ZA ISPORUKU JE POVEĆAN NA 5 DO 7 RADNIH DANA.</strong></p> */}
            </div>
        );
    }
};

export default Shipping;