export const Config = {
    perPage: 20,

    getNumberOfPages(numberOfProducts){
        return Math.ceil(numberOfProducts/this.perPage);
    },

    getOldPrice(n){
        return Math.floor(n * 1.1) + '.00';
    }
}