import React, { Component } from 'react';
import Service from '../components/Service';
import { Helmet } from 'react-helmet';

class Fail extends Component{

    render(){
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName}</title>
                </Helmet>

                <h1>Transakcija neuspešna!</h1>
                <p>Molimo vas proverite unesete podatke i probajte ponovo.</p>
            </div>
        );
    }

}

export default Fail;