import React, { Component, Fragment } from 'react';
import ProductView from '../components/ProductView';
import Slider from "react-slick";
import Sticker from '../components/Sticker';

// const sizes = {
//     1: '100%',
//     2: '50%',
//     3: '33%',
//     4: '25%',
//     5: '20%'
// }

class ProductSlider extends Component {

    render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.perPage,
      slidesToScroll: this.props.perPage,
      autoplay: true,
      initialSlide: 0
    };
    return (
      <Slider {...settings}>
          {this.props.products.map(function(item, index){
            let stickerNew = false;
              return(
                    // <li key={index} style={{width: sizes[this.props.perPage]}}>
                    <div className="top-products-container" key={index+'tps'} >
                      <div className="top-position" >{index+1}</div>
                        {/* Stickers */}
                          {item.categories.map(function(categories, index){
                              return(
                                      <span key={index}>
                                        {categories.name === "Novi naslovi" &&
                                          <Fragment>{stickerNew = true}</Fragment>
                                        }       
                                      </span>
                                    )}, this)
                          }

                          {/* {(item.sticker === 1 && stickerNew === false  && item.discount !== null) &&
                            <Sticker text={parseInt(item.discount)+"%"}/>
                          }
                          {(item.sticker === 1 && stickerNew === true && item.discount !== null) &&
                            <Sticker text={parseInt(item.discount)+"%"}/>
                          }
                          {(item.sticker === 1 && stickerNew === true && item.discount === null) &&
                            <Sticker text="NOVO"/>
                          } */}

                          <ProductView item={item} />
                    </div>
                            // </li>
                            // <p key={index}>{item.title}</p>   
                        )
                    },this)
          }
        {/* <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div> */}
      </Slider>
    );
  }


}

export default ProductSlider;
