import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import TopProducts from '../components/TopProducts';
import renderHTML from 'react-render-html';
import AddThis from '../components/AddThis';
import { Helmet } from 'react-helmet';

function images(image){
    return 'http://api.pp.cekaonica.com/media/news/'+ image;
}

class NewsSingle extends Component {

    constructor(props){
        super(props);
        this.state = {
            news: []
        }

    }
   
    componentDidMount(){
        Service.getNewsSingle({id: this.props.match.params.id},
            (res)=>{
                this.setState({news: res.data})
                }
        );
    }
    
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                    {/* <Categories /> */}
                </div>
                <div className="right-content">
                    {/* <h2>Vesti</h2> */}
                    {this.state.news.map(function(item, index){
                        return(
                            <div key={index} className="news-single-container">
                                <Helmet>
                                    <title>{Service.websiteTitleName} | {item.title}</title>
                                </Helmet>

                                <h2>{item.title}</h2>
                                <h4>{item.created_at}</h4>
                                {Service.storeId === '3' &&
                                    <Fragment>
                                        {/* <img src={images(item.image)} alt="vesti slika"  style={{width: '100%'}}/> */}
                                        {/* <img src={Service.images('news/'+item.image)} alt="vesti slika"  style={{width: '100%'}}/> */}
                                
                                        {/* <p style={{whiteSpace: 'pre-wrap'}}>{item.text}</p> */}
                                        <div> {renderHTML(item.text)} </div>
                                    </Fragment>
                                }
                                
                                {Service.storeId === '1' &&
                                    <Fragment>
                                        <div> {renderHTML(item.text)} </div>
                                        <AddThis url={window.location.href} title={ 'Hermonija knjige | '+item.title } />
                                    </Fragment>
                                }
                            </div>
                        )
                    },this)
                    }
                </div>
            </div>
        );
    }

}

export default NewsSingle;