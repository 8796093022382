import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Cart } from '../components/Cart';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Service from '../components/Service';

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }


class ProductViewRelated extends Component {


    addToCart = (product) => {
      Cart.addToCart(product);
      Cart.getCart();
    }

    render() {
        return (
            <div className="product-view" >
            <Link to={'/proizvod/'+this.props.item.product_id+'/'+this.props.item.slug.toLowerCase()} >
                <img alt="" src={Service.images(this.props.item.slug.toLowerCase() + '.jpg')} height="160" />
            </Link>
            <h3>{this.props.item.title}</h3>
            {/* <p>{this.props.item.author}</p>  */}
            {this.props.item.authors.map(function(author, index){
                return(
                    <span key={index}>
                        <p>{author.name}</p>
                    </span>
                )}, this)
            }
            {/* <button className="add-to-cart-button" onClick={()=>{this.addToCart(this.props.item)}}>Dodaj u korpu</button> */}
            </div>
        );
    }

}

export default ProductViewRelated;
