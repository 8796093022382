import React, { Component } from 'react';
import { Cart } from '../components/Cart';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Service from '../components/Service';
import Sticker from '../components/Sticker';
// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }

function getItemDiscount (item){
    //(40-30)/40 * 100;
    if(!item.discount){
        return Math.round((parseInt(item.full_price) - parseInt(item.price)) /  parseInt(item.full_price) * 100);
    }
    return parseInt(item.discount);
}

class ProductView extends Component {


    addToCart = (product) => {
      Cart.addToCart(product);
      Cart.getCart();
    }

    render() {
        return (
            <div className="product-view" >
            <a href={'/proizvod/'+this.props.item.id+'/'+this.props.item.slug.toLowerCase()} >
                <img alt="" src={Service.images(this.props.item.slug.toLowerCase() + '.jpg')} height="160" />
            </a>
            {this.props.item.preorder == 1 &&
                // <Sticker color="orange" text="Pretprodaja" fontSize="small"/>
                <div className="preorder">U pretprodaji</div>
            }
            <h3>{this.props.item.title}</h3>
            {this.props.item.authors.map(function(author, index){
                // if(index < 4){
                return(
                    
                    <span className="product-view-author" key={index}>
                        {author.name}
                        {/* <p>{author.name}</p> */}
                    </span>
                    
                )
                // }
                }, this)
            }
            {/* {this.props.item.sticker == 1 && */}
                <div className="stickers-container product-view-stickers">
                    {/* {this.props.stickerNew === true &&
                        <Sticker color="red" text="NOVO"/>
                    } */}
                    {parseInt(this.props.item.new) == 1 &&
                        <Sticker color="green" text="NOVO"/>
                    }
                    {/* {(this.props.item.total_discount !== null || parseInt(this.props.item.no_discount) == 0) && parseFloat(this.props.item.price) > 0 && */}
                    {this.props.item.total_discount &&
                        // <Sticker color="red" text={`${getItemDiscount(this.props.item)}%`} />
                        <Sticker color="red" text={`${this.props.item.total_discount}%`} />
                    }
                    {(this.props.item.free_shipping == 1 || parseInt(this.props.item.price) > 3000) &&
                        <Sticker color="blue" text="besplatna isporuka" paragraphId="free-shipping"/>
                    }
                    {this.props.item.discounts && this.props.item.discounts.length > 0 &&
                        <Sticker image={this.props.item.discounts[0].image} />
                    }
                    {this.props.item.actionSticker  &&
                        <Sticker image={this.props.item.actionSticker} link={this.props.item.actionUrl}/>
                    }
                </div>
            {/* } */}
            {/* <button className="add-to-cart-button" onClick={()=>{this.addToCart(this.props.item)}}>Dodaj u korpu</button> */}
            </div>
        );
    }

}

export default ProductView;
