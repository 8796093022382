import React, { Component } from 'react';
import Service from '../components/Service';
import {Link} from 'react-router-dom';

function stripHTML(s){
    let n = s.replace(/(<([^>]+)>)/ig,"");
    let a = n.split('&nbsp;');
    return a.join(' ');
}



class NewsSidebar extends Component {

    constructor(props){
        super(props);
        this.state = {
            news: []
        }

    }
 
    componentDidMount(){
        //this.getTopProducts();
        Service.getNews(
            (res)=>{
                //get just 5 news, @todo: da se ovo uradi u backendu!
                this.setState({news: res.data.slice(0, 5)});
                //this.setState({news: res.data.slice(0, 5)});
            }
        )
    }

    

    render() {
        return (
            <div className="categories-container top-books">
                <h2>Zanimljivosti</h2>
            {this.state.news.map(function(item, index){
                return(
                    <Link to={`/vest/${item.id}/${item.title}`} key={index}>
                    <div key={index} className="news-sidebar">
                        <h3>{item.title}</h3>
                        <p>{stripHTML(item.text.slice(0, 50))}...</p>
                    
                    </div>
                    </Link>
                )
            },this)
            }
            </div>
        );
    }

}

export default NewsSidebar;
