import React, { Component } from 'react';
import Service from '../components/Service';
import { Helmet } from 'react-helmet';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }

    componentDidMount(){
        this.getOrders();
    }

    getOrders=()=>{
        Service.get('/orders/get',{},(res)=>{
            this.setState({orders: res.orders})
        });
    }

    singleOrder=(hash)=>{
        window.location.href = "/porudzbina/"+hash;
    }
   
    render(){
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName} | Poružbine</title>
                </Helmet>
                
                <h2>Porudžbine</h2>

                <div className="orders orders-head">
                    <div className="id">ID</div>
                    <div className="name">Ime i prezime</div>
                    <div className="date">Datum</div>
                    <div className="status">Status porudžbine</div>
                    <div className="code">Kod</div>
                    <div className="action"></div>
                </div>
              
                {this.state.orders.length > 0 &&
                    <div>
                        {this.state.orders.map(function(item, index){
                            return(
                                <div className="orders">
                                    <div className="id">{item.id}</div>
                                    <div className="name">{item.name}</div>
                                    <div className="date">{item.created_at}</div>
                                    <div className="status">
                                        {item.statusText ? item.statusText : 'Pakovanje'}
                                    </div>
                                    <div className="code">{item.code}</div>
                                    <div className="action"><button onClick={()=>{this.singleOrder(item.hash)}}>Porudžbina</button></div>
                                </div>
                            )
                        }, this)}
                    </div>
                }
            </div>
        );
    }

}

export default Orders;