import React, { Component } from 'react';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import duix from 'duix';
import renderHTML from 'react-render-html';
import {Animated} from "react-animated-css";
import '../modal.css';

class Modal extends Component {

    constructor(props){
        super(props);
        this.state = {
            display: 'none',
            modalMessage: 'message',
            visible: false
        }

    }

    unsubscribe = []
 
    componentDidMount(){
        
        this.unsubscribe[0] = duix.subscribe('dialog', (value) => {
             this.showModal(value)
        });
        
    }

    componentWillUnmount(){
        //clearInterval(this.interval);
        this.unsubscribe[0]();
    }

    showModal=(message)=>{
        this.setState({modalMessage: message, display: 'block'});
        //setTimeout(()=>{this.hideModal()}, 4000);
    }

    hideModal=()=>{
        this.setState({display: 'none'});
    }


    render() {
        return (
            // <Animated animateOnMount={false}  animationIn="fadeIn" animationOut="fadeOut" isVisible={this.state.visible}>
            <div className="modal-container" style={{display: this.state.display}}>
                <div className="modal" >
                    <div className="modal-close-button" onClick={this.hideModal}>X</div>
                    <div>{renderHTML(this.state.modalMessage)}</div>
                    <div className="modal-ok-button" onClick={this.hideModal}>OK</div>
                </div>
            </div>
            // </Animated>
        );
    }

}

export default Modal;
