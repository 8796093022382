import React, { Component } from 'react';
import {Cart} from '../components/Cart';
import Service from '../components/Service';
import { Helmet } from 'react-helmet';

class Success extends Component{

    constructor(props){
        super(props);

        // remove all cart items after successful purchase
        Cart.clearCart();
        this.state = {
            order: {},
            products: []
        }

    }

    getOrderInfo(){
        Service.get('/orders/getOrderInfo',{id: this.props.match.params.oid},(res)=>{
            this.setState({order: res.data.order, products: res.data.order.products});
        })
    }

    componentDidMount(){
        this.getOrderInfo();
    }
    

    render(){
        return(
            <div className="content success">
                <Helmet>
                    <title>{Service.websiteTitleName}</title>
                </Helmet>

                <h1>Uspešno ste obavili kupovinu</h1>
                { this.props.match.params.tid === 'cc' &&
                    <div>
                        <p>ID Transakcije: {this.props.match.params.tid}</p>
                        {/* <p>Broj porudžbine: {this.props.match.params.oid}</p> */}
                        {/* <p>Ukupno: {this.props.match.params.ammount}</p> */}
                    </div>
                }
                <p>
                Broj porudžbine: {this.state.order.id}
                </p>

                <h3>{this.state.order.name}</h3>
                <p>{this.state.order.email}<br/>
                {this.state.order.phone}</p>

                <h2>Adresa</h2>
                    <p>
                        {this.state.order.billing_address}<br />
                        {this.state.order.zip_code} {this.state.order.city} <br />
                        {this.state.order.municipality}<br />
                        {this.state.order.country}<br />
                        {/* {this.state.order.billing_address}<br /> */}
                    </p>

                <h2>Artikli</h2>
                <table className="success-table">
                    <tbody>
                    <tr>
                        <td>Naslov</td>
                        <td>Cena</td>
                        <td>Količina</td>
                        <td>Ukupno</td>
                    </tr>
                
                    {/* <div> */}
                        {this.state.products.map(function(item, index){
                            return(
                                <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>{Service.price(item.unit_price)}</td>
                                    <td>{item.quantity} KOM.</td>
                                    <td>{Service.price(item.totalPriceForProduct)}</td>
                            {/* <p><b>{item.title}</b>, {item.unit_price} RSD x {item.quantity} KOM. ukupno: {item.price} RSD</p> */}
                                </tr>
                            )
                        },this)
                        }

                    </tbody>
                </table>
                {this.state.order.coupons &&
                    <div>
                        {this.state.order.coupons.map(coupon => {
                            return(
                                <div style={{display: 'inline-block', padding: '.5em', marginTop: '.5em', border: '1px solid gray', borderRadius: '.3em'}}>
                                    Kupon: <strong>{coupon.name}</strong> {coupon.description}
                                </div>
                            )
                        })}
                    </div>
                }
                <p>Isporuka: {Service.price(this.state.order.shipping_price)}</p>
                <p>Ukupno: {Service.price(this.state.order.total)}</p>
                { this.props.match.params.type === 'pp' &&
                    <p>Način plaćanja: <strong>pouzećem</strong></p>
                }
                { this.props.match.params.type === 'cc' &&
                    <p>Način plaćanja: <strong>kreditnom karticom</strong></p>
                }


            </div>
        );
    }

}

export default Success;