import React, { Component, Fragment } from 'react';
import { Cart } from '../components/Cart';
import Categories from '../components/Categories';
import Service from '../components/Service';
import { Auth } from '../components/Auth';
import duix from 'duix';
import RelatedProducts from '../components/RelatedProducts';
import AddThis from '../components/AddThis';
import renderHTML from 'react-render-html';
import {NotificationManager} from 'react-notifications';
//import Popup from "reactjs-popup";
import Slider from "react-slick";
import Sticker from '../components/Sticker';
import { Helmet } from 'react-helmet';

import StarRatings from 'react-star-ratings';
import product_1 from '../templates/product_1';
import product_2 from '../templates/product_2';

let template = product_1;
if(Service.storeId == 2){
    template = product_2;
}

//import ReactGA from 'react-ga';



// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }

const Info = props => (
  
    <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
);

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

function getItemDiscount (item){
    //(40-30)/40 * 100;
    if(!item.discount){
        return Math.round((parseInt(item.full_price) - parseInt(item.price)) /  parseInt(item.full_price) * 100);
    }
    return parseInt(item.discount);
}


class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            comment: '',
            comments: [],
            relatedProducts: [],
            helpClass: 'help-hidden',
            pending: false,
            notify: false,
            productsCount: 3,
            imageWidth: 232,
            images: [],
            currentImage: '',
            selectedImage: 0,
            productImages: [],
            modalImagesVisible: false
        }
        this.slider = React.createRef();
    }

    componentDidMount() {

        this.updateProducts();
        this.getComments();
        this.updateRelatedProducts();
        this.getNotification();
        
        
      }

    zoomImage=()=>{
        if(this.state.imageWidth === 232){
            this.setState({imageWidth: 350});
        }else{
            this.setState({imageWidth: 232});
        }
    }
    resetImageZoom=()=>{
        this.setState({imageWidth: 232});
    }

    componentWillReceiveProps(nextProps){
        setTimeout(()=>{
            this.updateProducts();
            this.getComments();
            this.updateRelatedProducts();
            this.getNotification();
        },10);

        
    }

    addToCart(productId, product){
      Cart.addToCart(productId, product);
      Cart.getCart();
      //this.props.updateCartDisplay();
    }

    removeOneFromCart(product){
      Cart.removeOneFromCart(product);
      Cart.getCart();

    }
  
    removeFromCart(product){
      Cart.removeFromCart(product);
      Cart.getCart();
    }

    updateProducts = () => {
        Service.getProductById({
            id: this.props.match.params.id, 
            } ,(products)=>{
                this.setState({products: products, currentImage: products[0] ? Service.images(products[0].slug.toLowerCase() + '.jpg') : null},()=>{
                    this.getSecondaryImages();
                    if(products[0]){
                        // send fb pixel
                        window.fbq('track', 'ViewContent',
                            {
                                content_ids: [this.props.match.params.id],
                                content_type: 'product',
                                content_name: products[0] ? products[0].title : null,
                                eventref: '' // or set to empty string
                            }
                        );

                        window.gtag('event', 'select_content', {
                            "content_type": "product",
                            "items": [
                            {
                                "id": this.props.match.params.id,
                                "name": products[0].title,
                                "quantity": 1
                            }
                            ]
                        });
                    }

                });
                if(products[0]){
                    if(parseInt(products[0].status) === 2){
                        this.setState({pending: true});
                    }
                }
        });
        
      }

    updateRelatedProducts=()=>{
        Service.get('/products/relatedProducts',{productId: this.props.match.params.id},(res)=>{
            
            let productsCount = 0;
            if(res.data.products.length < 5){
                    productsCount = res.data.products.length;
                }else{
                    productsCount = 5;
                }
                if(window.screen.width < 720){
                    productsCount = 2;
                }
            this.setState({productsCount: productsCount});
            this.setState({relatedProducts: res.data.products});
        })
    }

    getNotification=()=>{
        setTimeout(()=>{
        Service.get('/products/getNotification',{productId: this.props.match.params.id},(res)=>{
            if(res.success){
                this.setState({notify: res.notify});
            }
        })
        },100);
    }

    changeImage=(event)=>{
        event.stopPropagation();
        let i = this.state.selectedImage;
        if(event.target.id === 'next'){
            i ++;
            if(i >= this.state.productImages.length){
                i = 0;
                
            }
            this.setState({selectedImage: i});
            return;
        }
        if(event.target.id === 'prev'){
            i --;
            if(i < 0){
                i = this.state.productImages.length -1;
            }
            this.setState({selectedImage: i});
            return;
        }

        // if image thumb clicked, change selected image and show popup
        this.slider.slickGoTo(event.currentTarget.id);
        this.setState({selectedImage: event.currentTarget.id, modalImagesVisible: 'block'});
        
    }

    hideImagePreview=(event)=>{
        event.stopPropagation();
        // this.setState({modalImagesVisible: 'none'});
        if(event.target.id === 'image-preview'){
            this.setState({modalImagesVisible: 'none'});
        }
    }

    notifyMe=(productId)=>{
        if(!Auth.isAuthenticated){
            NotificationManager.error('Morate biti ulogovani kako biste koristili obaveštenja o knjigama. Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
            // alert('Morate biti ulogovani kako biste koristili obaveštenja o knjigama.')
        }else{
            Service.get('/products/notify',{productId: this.props.match.params.id, storeId: 3},(res)=>{
                if(!res.success){
                    NotificationManager.error('Morate biti ulogovani kako biste koristili obaveštenja o knjigama. Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
                }
                this.getNotification(); 
            })
        }
    }

    addToWishlist= () => {
        if(Auth.isAuthenticated){
        Service.addToWishlist({
            productId: this.props.match.params.id, 
            Auth
            },()=>{
                //let today = new Date();
                //duix.set('', 1);
                duix.set('wishlistItems', 1);
                // duix.set('modal', 'Proizvod je dodat u listu želja.');
                NotificationManager.success('Proizvod je dodat u listu želja.', 'Lista želja:')
                window.fbq('track', 'AddToWishlist', {
                    content_name: this.state.products[0].title, 
                    content_category: 'Knjige',
                    content_ids: [this.props.match.params.id],
                    content_type: 'product',
                    value: parseInt(this.state.products[0].price),
                    currency: "RSD" 
                });

                window.gtag('event', 'add_to_wishlist', {
                    "currency": 'RSD',
                    "items": [{
                      "id": this.props.match.params.id,
                      "name": this.state.products[0].title,
                      price: this.state.products[0].price,
                      quantity: 1,
                    }],
                    "value": this.state.products[0].price
                });
                
            });
        }else{
            NotificationManager.error('Morate biti ulogovani da biste koristili listu želja. Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
            // alert('Morate biti ulogovani da biste koristili listu želja.');
        }
    }



    handleComment = (event) => {
        this.setState({comment: event.target.value});
    }

    addComment = () => {
        if(!Auth.isAuthenticated){
            NotificationManager.error('Morate biti ulogovani da biste postavili komentar. Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
            // alert('Morate biti ulogovani da biste postavili komentar.');
            return;
        }
        Service.addComment({Auth, productId: this.props.match.params.id, text: this.state.comment},
            (result)=>{
                NotificationManager.info('Komentar je uspešno poslat. Vaš komentar će biti vidljiv kada ga odobri administrator.');
                this.setState({comment: ''});
            }
          
        )
        
    } 

    getComments = () => {
        Service.getComments({productId: this.props.match.params.id},
            (result)=>{
                if(result.data.comments){
                    this.setState({comments: result.data.comments})
                }
            }
            
        );
    } 

    changeRating=(rate, name)=>{
        if(Auth.isAuthenticated){
            Service.rateProduct({Auth, productId: name, rating: rate},(res)=>{
                this.updateProducts();
            });
        }else{
            NotificationManager.error('Morate biti ulogovani! Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
            // alert('Morate biti ulogovani da biste ocenili proizvod!');
        }
    }



    toggleHelp=()=>{
        if(this.state.helpClass === 'help-hidden'){
            this.setState({helpClass: 'help-visible'});
        }else{
            this.setState({helpClass: 'help-hidden'});
        }
    }

    getSecondaryImages=()=>{
        Service.get('/products/getImages',{
            productId: this.props.match.params.id
            },(res)=>{
                if(res.success){
                    this.setState({images: res.data.images},()=>{
                        this.updateProductImages();
                    })
                    
                }
            });
    }

    updateProductImages=()=>{
        let i = [];
        i.push(this.state.currentImage);
        this.state.images.map(function(item){
            i.push(Service.secondaryImages + item.image_name);
        })
        this.setState({productImages: i});
    }

    authorNameLink=(authorName)=>{
        let parseAuthorName = authorName.toLowerCase().replace(/ /g, "%20");
        let link = '/proizvodi/filter/' + parseAuthorName;
        
        return link;
    }

    render=template


}

export default Product;