import React, { Component, Fragment } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { Helmet } from 'react-helmet';
class UserOrders extends Component {

constructor(props){
    super(props);
    this.state = {
      orders: []
    }    
}

componentDidMount(){
    this.getUserOrders();
}
  
getUserOrders=()=>{
    Service.get('/users/orders', {},(res)=>{
        if(res.success){
           this.setState({orders: res.data})
        }
    })
}

selectedOrder=(hash)=>{
    window.location.href = "/success/pp/"+hash;
}

render() {
return (
    <div className="user-orders-container">
        <Helmet>
            <title>{Service.websiteTitleName} | Poružbine</title>
        </Helmet>

        <h2>Poružbine</h2>
        <div className="user-orders user-orders-head">
                <div className="id">Broj porudžbine</div>
                <div className="address">Adresa</div>
                <div className="date">Datum</div>
                <div className="total">Ukupno</div>
                <div className="action"></div>
            </div>
        
            {this.state.orders.map(function(item, index){
            return(
                <div className="user-orders">
                    <div className="id">{item.id}</div>
                    <div className="address">{item.shipping_street} {item.shipping_street_number}</div>
                    <div className="date">{item.created_at}</div>
                    <div className="total">{item.total}</div>
                    <div className="action"><button onClick={()=>{this.selectedOrder(item.hash)}}>Porudžbina</button></div>
                </div>
            )
            }, this)}
    </div>
    );

    
}

}

export default UserOrders;