import React, { Component } from 'react';
import { Cart } from '../components/Cart';
import Service from '../components/Service';
import duix from 'duix';
class CartDisplay extends Component {

    constructor(props){
        super(props);
        this.state = {
            numberOfProducts: 0,
            total: 0,
            cartIsEmpty: true
        }
    }

    unsubscribe = []
 
    componentDidMount(){
        Cart.initCart(); // get cart items from the local storage
        this.unsubscribe[0] = duix.subscribe('cartItems', this.numberOfCartProducts);
    }

    componentWillUnmount(){
        this.unsubscribe[0]();
    }

    numberOfCartProducts=(cart)=>{
        this.setState({
            numberOfProducts: cart.cartProducts,
            total: cart.total
        });
    }

    render(){
        return(
            <span>
                {(Service.storeId === '2') &&
                    <span>
                        <img src="/korpa-stela.png"/>
                        <span className="stela-number-of-cart-items">{this.state.numberOfProducts}</span>

                    </span>
                }            
                <span className="cart-text"><span className="header-stela-cart-text">Korpa</span>
                {(Service.storeId === '1' || Service.storeId === '3') &&
                    <br />
                }
                    {Service.price(this.state.total)} 
                </span>
                {(Service.storeId === '1' || Service.storeId === '3') &&
                    <span>
                        <i className="fa fa-shopping-cart cart-image" ></i>
                        <span>{this.state.numberOfProducts}</span>
                    </span>
                }
                
            </span>
        );
    }
}

export default CartDisplay;
