import React, { Component } from 'react';
import {Link} from 'react-router-dom';


class Sticker extends Component{
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: ''
    }  
  }

  componentDidMount(){
    if(this.props.color === "red"){
      this.setState({imageUrl: '/images/sticker-red.png'})
    }else if(this.props.color === "blue"){
      this.setState({imageUrl: '/images/sticker-blue.png'})
    }else if(this.props.color === "green"){
      this.setState({imageUrl: '/images/sticker-green.png'})
    }else if(this.props.color === "turqoise"){
      this.setState({imageUrl: '/images/sticker-turqoise.png'})
    }else if(this.props.color === "orange"){
      this.setState({imageUrl: '/images/sticker-orange.png'})
    }
    if(this.props.image){
        this.setState({imageUrl: this.props.image})
    }
  }

    render() {
      return(
        <div className={`sticker-box`}>
          {this.props.link ? 
          <Link to={this.props.link} ><img src={this.state.imageUrl}/></Link>
          :
          <img src={this.state.imageUrl}/>
          }
            
            {this.props.fontSize == 'small' &&
              <p id={this.props.paragraphId} data-id="aaa" className="sticker-font-small">{this.props.text}</p>
            }
            {!this.props.fontSize &&
              <p id={this.props.paragraphId} data-id="bbb">{this.props.text}</p>
            }
            
        </div>
      )
    }
  }


export default Sticker;