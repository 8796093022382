import React, { Component } from 'react';
import Service from '../components/Service';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  ViberShareButton,
  EmailShareButton
} from 'react-share';

// import {
//   FacebookShareCount,
//   PinterestShareCount,
//   VKShareCount,
//   OKShareCount,
//   RedditShareCount,
//   TumblrShareCount,
// } from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  ViberIcon,
  EmailIcon,
} from 'react-share';


class AddThis extends Component {

    constructor(props){
        super(props);
        this.state = {
            rnd: 0
        }

    }

    round = false;
    radius = 10

 
    componentDidMount(){
        // setTimeout( () => {
        // var addthisScript = document.createElement('script');
        // addthisScript.setAttribute('src', 'http://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d728325401139e1')
        // if (document.body) document.body.appendChild(addthisScript)
        // },10);
    }

    componentWillReceiveProps(nextProps){
        // this.setState({rnd: Math.random()},()=>{
        //     setTimeout( () => {
        //     var addthisScript = document.createElement('script');
        //     addthisScript.setAttribute('src', 'http://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d728325401139e1')
        //     if (document.body) document.body.appendChild(addthisScript)
        //     },10);
        // })
        
    }


    render() {
        return (
            <div className="social-icons">
                <div className="social-icon" >
                    <FacebookShareButton 
                        url={this.props.url}
                        quote={this.props.title}>
                        {(Service.storeId == 1 || Service.storeId == 3) &&
                            <FacebookIcon size={32} round={this.round} borderRadius={this.radius}/>
                        }
                        {Service.storeId == 2 &&
                            <img src="/fb-icon-stela.png" /> 
                        }
                    </FacebookShareButton>
                    {/* <FacebookShareCount url={this.props.url} /> */}
                </div>
                <div className="social-icon" >
                    <TwitterShareButton 
                        url={this.props.url}
                        title={this.props.title}>
                        {(Service.storeId == 1 || Service.storeId == 3) &&
                            <TwitterIcon size={32} round={this.round} borderRadius={this.radius}/>
                        }
                        {Service.storeId == 2 &&
                            <img src="/twitter-icon-stela.png" /> 
                        }
                    </TwitterShareButton>
                    {/* <TwitterShareCount url={this.props.url} /> */}
                </div>
                <div className="social-icon" >
                    <EmailShareButton 
                        url={this.props.url}
                        title={this.props.title}>
                        {(Service.storeId == 1 || Service.storeId == 3) &&
                            <EmailIcon size={32} round={this.round} borderRadius={this.radius}/>
                        }
                        {Service.storeId == 2 &&
                            <img src="/email-icon-stela.png" /> 
                        }
                    </EmailShareButton>
                    {/* <EmailShareCount url={this.props.url} /> */}
                </div>
                <div className="social-icon" >
                    <ViberShareButton 
                        url={this.props.url}
                        title={this.props.title}>
                        {(Service.storeId == 1 || Service.storeId == 3) &&
                            <ViberIcon size={32} round={this.round} borderRadius={this.radius}/>
                        }
                        {Service.storeId == 2 &&
                            <img src="/viber-icon-stela.png" /> 
                        }
                    </ViberShareButton>
                    {/* <EmailShareCount url={this.props.url} /> */}
                </div>
                
                {/* <FacebookIcon size={32} round={false} borderRadius={10}/>
                <FacebookShareCount url={this.props.url} /> */}
                {/* <div className="addthis_inline_share_toolbox" data-url={  this.props.url } data-title={ this.props.title }></div> */}
            </div>
        );
    }

}

export default AddThis;
