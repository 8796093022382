import React, { Component } from 'react';

// import $ from 'jquery';

class Test extends Component {


    render(){
        return(
            
            <div className="test-container">
                {/* <div className="sample-container">
                    <div id="container1">
                        <a href="https://online.flippingbook.com/view/785316/" className="fbo-embed" data-fbo-id="785316" data-fbo-lightbox="yes" 
                        data-fbo-width="740px" data-fbo-height="480px" data-fbo-version="1" style={{maxWidth: 100 + "%"}}>Krcko april 2020</a>
                    </div>
                </div> */} 
                {/* mali-krcko-april-2020 */}
                <iframe className="pdf-iframe" src={"/pdf/web/viewer.html?file="+this.props.match.params.file+".pdf"} ></iframe>
                
                    {/* <a href="/ViewerJS/#../krcko-april-20202.pdf"></a>
                    <iframe className="pdf-iframe " src="/ViewerJS/#../krcko-april-20202.pdf" >
                    </iframe> */}
                    {/* <object data="krcko-april-2020.pdf" type="application/pdf">
                        <p>Your web browser doesn't have a PDF plugin.
                        Instead you can  
                            <a href="krcko-april-2020.pdf">
                                click here to
                                download the PDF file.
                            </a>
                        </p>
                    </object> */}
            </div>
        );
    }

}

export default Test;