import React, { Component, Fragment } from 'react';
import ProductViewRelated from '../components/ProductViewRelated';
import Slider from "react-slick";
import Sticker from '../components/Sticker';
import Service from '../components/Service';


// const sizes = {
//     1: '100%',
//     2: '50%',
//     3: '33%',
//     4: '25%',
//     5: '20%'
// }

class RelatedProducts extends Component {

    next = () => {
        this.slider.slickNext();
    }
    previous = () => {
        this.slider.slickPrev();
    }

    render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.perPage,
      slidesToScroll: this.props.perPage
    };

    
    return (
        <div style={{position: 'relative'}}>
      <Slider ref={c => (this.slider = c)} {...settings}>
          {this.props.products.map(function(item, index){
              let stickerNew = false;
            return(
                    // <li key={index} style={{width: sizes[this.props.perPage]}}>
                    <div className="related-product-single">
                      {/* Stickers */}
                      {item.categories.map(function(categories, index){
                              return(
                                      <span key={index}>
                                        {categories.name === "Novi naslovi" &&
                                          <Fragment>{stickerNew = true}</Fragment>
                                        }       
                                      </span>
                                    )}, this)
                      }

                      {/* <div className="stickers-container related-products-stickers">
                        <Sticker color="red" text="NOVO"/>
                        <Sticker color="green" text="30%"/>
                        <Sticker color="blue" text="besplatna isporuka" paragraphId="free-shipping"/>
                        <Sticker color="turqoise" text="multi"/>
                      </div>         */}
                      {/* {(item.sticker === 1 && stickerNew === false  && item.discount !== null) &&
                        <Sticker text={parseInt(item.discount)+"%"}/>
                      }
                      {(item.sticker === 1 && stickerNew === true && item.discount !== null) &&
                        <Sticker text={parseInt(item.discount)+"%"}/>
                      }
                      {(item.sticker === 1 && stickerNew === true && item.discount === null) &&
                        <Sticker text="NOVO"/>
                      } */}

                      <ProductViewRelated item={item} key={index} numbers={true}/>
                    </div>
                    // </li>
                    // <p key={index}>{item.title}</p>   
                  )
            },this)
          }
        {/* <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div> */}
        
      </Slider>

            {Service.storeId == 2 &&
                // <div className="slider-custom-arrows-container">
                <Fragment>
                    <button className="slider-custom-button-left" onClick={this.previous}>
                        <img src="/strelica-stela-roze-levo.png" />
                    </button>
                    <button className="slider-custom-button-right" onClick={this.next}>
                        <img src="/strelica-stela-roze-desno.png" />
                    </button>
                </Fragment>
                // </div>
        }
      </div>
    );
  }


}

export default RelatedProducts;
