import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';


class Chat extends Component {

    constructor(props){
        super(props);
        this.state = {
          chatVisible: false,
          inputValue: '',
          chatId: '',
          messages: []
        }
    }

    componentDidMount(){
        this.setChatId();
    }

    showChat=()=>{
        this.setState({chatVisible: true},()=>{
            this.getMessages();
        })
    }

    hideChat=()=>{
        this.setState({chatVisible: false},()=>{
        })
    }

    handleInput=(event)=>{
        this.setState({inputValue: event.target.value}); 
    }

    setChatId=()=>{
        if(Auth.userId){
            this.setState({chatId: Auth.userId})
        }else if(localStorage.getItem('ppChatId')){
            let chatId = localStorage.getItem('ppChatId');
            this.setState({chatId: chatId})
        }else{
            localStorage.setItem('ppChatId', this.generateChatId())
            this.setChatId();
        }
    }

    generateChatId=()=>{
        let unixTime = new Date().getTime() / 1000;
        return 'gcid' + parseInt(unixTime);
    }

    sendMessage=()=>{
        Service.get('/chat/sendMessage',{
            chatId: this.state.chatId,
            message: this.state.inputValue
        },(res)=>{
           if(res.success){
                this.getMessages();
                this.setState({inputValue: ''})
           }
       })
    }

    enterPressSendMessage = (e) => {
        if(e.which === 13) {
          this.sendMessage();
        }
    }

    getMessages=()=>{
        Service.get('/chat/get',{
            chatId: this.state.chatId,
        },(res)=>{
           if(res.success){
                this.setState({messages: Object.values(res)},()=>{
                    if(this.state.chatVisible === true){
                        let element = document.getElementById("scrollbar-bottom");
                        element.scrollTop = element.scrollHeight;
                    }
                    setTimeout(()=>{this.getMessages()}, 6000);
                });
           }
       })
    }

    render(){
        return(
            <div>
                {!this.state.chatVisible &&
                    <div className="chat-box" onClick={this.showChat}>
                        <p>Pitajte nas?</p>
                        <i className="far fa-comment"></i>
                    </div>
                }
                
                {this.state.chatVisible &&
                    <div className="chat">
                        <div className="top">
                            <p>Publik praktikum:</p>
                            <i className="fas fa-times" onClick={this.hideChat}></i>
                        </div>

                        <div className="messages" id="scrollbar-bottom">
                            {this.state.messages.map(function (item, index) {
                                return(
                                    <div key={index}>
                                        {this.state.chatId === item.msg_from &&
                                            <div className="my-message">
                                                <p>{item.message}</p>
                                            </div>
                                        }
                                        {this.state.chatId !== item.msg_from &&
                                            <div className="from-message">
                                                <p>{item.message}</p>
                                            </div>
                                        }
                                    </div>
                                )
                            },this)}
                        </div>

                        <div className="bottom">
                            <input type="text" value={this.state.inputValue} onChange={this.handleInput} onKeyPress={this.enterPressSendMessage} />
                            <button onClick={this.sendMessage} onKeyPress={this.enterPressSendMessage}><i className="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default Chat;
